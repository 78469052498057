import React from 'react';
import { withRouter } from 'react-router-dom';
import injectStyles from 'react-jss';
import StandardError from './StandardError';
import { FormattedMessage, injectIntl } from 'react-intl';

const InternalServerError = ({ classes, details, intl, showDescription = true }) => (
  <StandardError
    error={500}
    header={
      <FormattedMessage id="internal-server-title" defaultMessage={'Internal Server Error!'} />
    }
    headerClass={classes.header__class}
    context={intl.formatMessage({
      id: 'app-500-context',
      defaultMessage: 'Please contact support if this continues to happen.',
    })}
    description={
      <React.Fragment>
        {showDescription && (
          <p>
            <FormattedMessage
              id="internal-server-description"
              defaultMessage={'Something thing went wrong with our server.'}
            />
          </p>
        )}
        {details && <p>{details}</p>}
      </React.Fragment>
    }
    footer={
      <div onClick={() => window.location.reload(true)}>
        <FormattedMessage id="internal-server-reload" defaultMessage={'Refresh'} />
      </div>
    }
  />
);

const styles = theme => ({
  header__class: {
    fontSize: '3em',
    color: 'red',
    marginTop: '2em',
  },
});

export default withRouter(injectIntl(injectStyles(styles)(InternalServerError)));
