export default {
  'en-US': {
    // Action Menu
    'action-menu-actions': 'Actions',
    'action-menu-add-association': 'New Threat Association',
    'action-menu-delete-threat': 'Delete Threat',
    'action-menu-delete-workspace': 'Delete Workspace',
    'action-menu-edit-labels': 'Add/Edit Labels',
    'action-menu-edit-workspace': 'Edit Workspace',
    'action-menu-export': 'Export',
    'action-menu-include-in-collection': 'Include in Collection',
    'action-menu-view-threat-results': 'View Associated Threats',
    'action-menu-view-search-results': 'View Search Results',
    'action-menu-view-whois-results': 'View Reverse Whois',
    'action-menu-save-search': 'Save Search',
    'action-menu-set-workspace': 'Set as default Workspace',
    'action-menu-re-run-original': 'Re-run Original Search',
    'action-menu-re-run-with-filters': 'Re-run Filtered Search',

    // Collection View All
    'active-element-table-header-riskAssoc': 'Risk Associations',

    // ASN Peers
    'asn-no-peers': 'No Peers',
    'asn-upstream-peers': 'Upstream Peers',
    'asn-downstream-peers': 'Downstream Peers',
    'asn-peer-table-element-name': 'Element',

    // Add Threat Association Modal
    'add-association-error': 'An error has occurred. Please try again.',
    'add-association-form-subtitle': 'User-Defined Threat',
    'add-association-form-title': 'New Threat Association',
    'add-association-no-threats': 'There are no existing threats that can be associated.',
    'add-association-select-placeholder': 'Select Threat',
    'add-association-success': 'Successfully created association.',

    // Admin
    'admin-action-api-tokens': 'Manage API Access Tokens',
    'admin-action-manage-members': 'Manage Members',
    'admin-action-manage-permissions': 'Manage Permissions',
    'admin-action-remove': 'Remove',
    'admin-action-reset-password': 'Reset Password',
    'admin-action-update': 'Update',
    'admin-oauth2-mapping': 'Oauth2: {oauth2Mapping}',
    'admin-oauth2-mapping-set-help':
      "Configure this value in Okta to allow this organization's users to log in.",
    'admin-oauth2-mapping-unset-help':
      "Set this value and configure it in Okta to allow this organization's users to log in.",
    'admin-sidebar-groups-description': 'Create, update, and remove groups for the organization',
    'admin-sidebar-groups-title': 'Group Management',
    'admin-sidebar-users-description': 'Create, update and remove users for the organization',
    'admin-sidebar-users-title': 'User Management',
    'admin-sidebar-workspaces-description':
      'Create, update, and remove workspaces for the organization',
    'admin-sidebar-workspaces-title': 'Workspaces',
    'global-permissions-loading': 'Loading Permissions...',
    'workspace-permissions-loading': 'Loading Permissions...',

    // App
    'app-back-button': 'Back',
    'app-400-context': "Please contact support if this error isn't valid.",
    'app-404-context': 'Please select a workspace.',
    'app-500-context': 'Please contact support if this continues to happen.',
    dismiss: 'Dismiss',
    error: 'Error',
    'app-table-no-data': 'No Data To Show',
    'app-table-totalhits-error': 'totalHits must be a number.',
    'app-table-results-error': 'results must be an array.',
    'app-view-all': 'View All',
    'app-view-all-count': 'View All ({count})',
    loading: 'Loading...',

    // Auth
    'auth-bootstrapping-user': 'Initializing User...',
    'auth-logout-error': 'An error occurred while logging out.',
    'auth-not-validate': 'Could not validate session.',

    // Bad Gateway
    'bad-gateway-title': 'Unable to Connect',
    'bad-gateway-description': 'We are unable to connect at this time.',
    'bad-gateway-refresh': 'Refresh',

    // Bad Request
    'bad-request-title': 'Bad Request',

    // Buttons
    'button-add': 'Add',
    'button-adding': 'Adding',
    'button-cancel': 'Cancel',
    'button-clear': 'Clear',
    'button-delete': 'Delete',
    'button-no': 'No',
    'button-processing': 'Processing...',
    'button-remove': 'Remove',
    'button-removing': 'Removing...',
    'button-save': 'Save',
    'button-submitting': 'Submitting',
    'button-yes': 'Yes',

    // Change Password
    'change-password-form-title': 'Change Password - {username}',
    'change-password-form-current': 'Current Password',
    'change-password-form-new': 'New Password',
    'change-password-form-confirm': 'Confirm Password',
    'change-password-form-success': 'Changed password',

    // Errors
    'more-errors': '+{numErrors} more',

    // Label Editor
    'add-label': 'Add Label',
    'add-label-description': 'Apply a label to the {count} selected elements.',
    'add-label-success': 'Added label to elements.',
    'add-label-title': 'Add Label',
    'label-editor-description':
      'Type new label and press enter or paste a list of comma separated labels into the field.',
    'label-editor-labels-updated': 'Updated element labels.',
    'label-editor-title': 'Label Editor - {name}',
    'remove-label': 'Remove Label',
    'remove-label-description': 'Remove a label from the {count} selected elements.',
    'remove-label-none': 'These elements have no labels to remove.',
    'remove-label-success': 'Removed label from elements.',
    'remove-label-title': 'Remove Label',

    // Password Strength
    'password-requirements': 'Password must meet the following requirements:',
    'password-requirements-part-1': '8-50 characters',
    'password-requirements-part-2': 'at least one upper case character (i.e. A-Z)',
    'password-requirements-part-3': 'at least one lower case character (i.e. a-z)',
    'password-requirements-part-4': 'at least one numeral (i.e. 0-9)',
    'password-requirements-part-5':
      'at least one special character (i.e. -=[]\\\\;,"`\'./~!@\\#$%^&*()_+\\{\\}|:<>?)',
    'password-requirements-part-6': 'and new password should match confirmation',

    //Collection Create Form
    'create-collection-form-assignee':
      'Assign users to collection. Assignment can be used for ownership and tasking.',
    'create-collection-form-description': 'Description',
    'create-collection-form-description-label': 'Description',
    'create-collection-form-name': 'Name',
    'create-collection-form-name-label': 'Name',
    'create-collection-form-nested-title': `Add Nested Collection into "{collectionName}"`,
    'create-collection-form-title': 'Add Workspace Collection',

    // Collection Include Form
    'collection-include-form-title': 'Include in Collection',
    'collection-include-form-create-help': 'create a new collection.',
    'collection-include-form-create-help-or': 'Create a new collection, or',
    'collection-include-form-create-help-select': 'select from the list of collections.',
    'collection-include-form-create-help-select-or': 'Select from the list of collections, or',
    'collection-include-form-create-help-select-from-list': 'Select from the list of collections.',
    'collection-include-form-max-collections':
      'You cannot add more collections as you have reached your limit.',
    'collection-include-form-name': 'Name',
    'collection-include-form-description': 'Description',
    'collection-include-form-collection': 'Collection',
    'collection-include-form-parent-collection': 'Parent Collection',
    'collection-include-form-parent-collection-placeholder': 'Choose a parent collection...',
    'collection-include-form-create-parent-help':
      'If none selected, a workspace collection is created by default.',
    'collection-include-form-rules-success': 'Added {count} elements into collection.',
    'collection-include-form-elements-help':
      'Only asns, cidrv4s, fqdns, ipv4s, and owners can be included.',

    // Collection Management
    'collection-management-include': 'Include in Collection',
    'collection-management-exported-members': 'Export complete.',
    'collection-management-exporting-members': 'Fetching collection members for export...',
    'collection-management-exported-rules': 'Export complete.',
    'collection-management-exporting-rules': 'Fetching collection rules for export...',
    'collection-management-exported-threat-associations': 'Export complete.',
    'collection-management-exporting-threat-associations':
      'Fetching threat associations for export...',
    'collection-management-remove-rules': 'Remove Rules',
    'collection-management-import-rules': 'Import',
    'collection-management-export-rules-all': 'Export All',

    // Delete Threat Form
    'delete-threat-error': 'An error has occurred.',
    'delete-threat-form-association-number':
      '{totalAssociations} elements will no longer be associated.',
    'delete-threat-form-confirmation-message': 'Are you sure you want to delete {name}?',
    'delete-threat-form-title': 'Delete Threat',
    'delete-threat-success': 'Successfully Deleted Threat.',

    // Forms
    'form-processing': 'Processing...',
    'form-submit': 'Submit',
    'form-save': 'Save',
    'form-create': 'Create',
    'form-cancel': 'Cancel',
    'form-edit': 'Edit',
    'form-add': 'Add',
    'form-input': 'Enter value',
    'form-no-change': 'No fields were updated',
    'form-select-type': 'Select Type',
    'form-unsaved-changes-description':
      'Unsaved changes will be lost if you navigate away from this page.',
    'form-unsaved-changes-title': 'Are you sure you want to leave this page?',
    'form-unsaved-changes-stay': 'Stay',
    'form-unsaved-changes-leave': 'Leave Page',

    // Four Oh Four
    'fourOhFour-title': 'Oops!',
    'fourOhFour-description': "We can't find the page you are looking for.",
    'fourOhFour-back': 'Back',

    // Forgot Password
    'forgot-password-title': 'Forgot Password?',
    'forgot-password-login': 'Return to Login',
    'forgot-password-help':
      "If you can't access ScoutPrime, fill in this form with your username. You will receive an email on how to access your account again.",

    // Reset Password Form
    'forgot-password-error-title': 'The following error occurred:',
    'forgot-password-form-username-placeholder': 'Username',

    // Incident Management
    'incident-management-create': 'Create Incident',

    // Incident Form
    'incident-form-create-help-select': 'select from the list of folders.',
    'incident-form-create-help-or': 'Create a new incident folder, or',
    'incident-form-name': 'Folder Name',
    'incident-form-elements-help': 'Only asns, cidrv4s, fqdns, and ipv4s can be included.',
    'incident-form-create-help-select-or': 'Select from the list of folders, or',
    'incident-form-create-help': 'create a new incident folder.',
    'incident-form-create-success': 'Created Incident - {number}',
    'incident-form-title': 'Create Incident',
    'incident-form-status': 'Status',
    'incident-form-status-placeholder': 'Choose an incident status...',
    'incident-form-folder': 'Folder',
    'incident-form-folder-placeholder': 'Choose an incident folder...',
    'incident-form-folder-help':
      'Click "create a new incident folder" to add a new folder instead.',
    'incident-form-assignee': 'Assign To',
    'incident-form-assignee-placeholder': 'Assign incident to user...',
    'incident-form-parentFolder': 'Parent Folder',
    'incident-form-parentFolder-placeholder': 'Choose a parent incident folder...',
    'incident-form-folder-success': 'Created Incident Folder - {name}',
    'incident-form-incident-success': ' Created Incident',
    'incident-form-creation-success': 'Added {count} elements into incident.',

    // Internal Server Error
    'internal-server-details': 'Details: {details}',
    'internal-server-title': 'Internal Server Error!',
    'internal-server-description': 'Something thing went wrong with our server.',
    'internal-server-reload': 'Refresh',

    // Login Form
    'login-form-username': 'Username',
    'login-form-username-placeholder': 'Username',
    'login-form-password': 'Password',
    'login-form-button': 'Log In',
    'login-okta-form-button': 'Continue to Sign In',
    'login-form-invalid-credentials': 'Invalid Credentials Provided',
    'login-form-request-error': 'Error processing request. Connection to server could not be made.',

    // Login Page
    'login-accessibility-help': 'Accessibility Help',
    'login-contact-support': 'Contact Support',
    'login-forgot-password': 'Forgot Password?',
    'login-last-accessed': 'Last Accessed on',

    // Logout
    'logout-reason-login-failed': 'Failed to authenticate with this server.',
    'logout-reason-unauthenticated':
      'You were logged out. Your current session expired. Please log in again.',
    'logout-reason-unknown': 'Successfully logged out!',
    'logout-redirect-text': 'You will be redirected back to login shortly.',
    'logout-title': 'Logging out...',

    // Metrics
    'metrics-sidebar-description-imports': 'Pull-based data loads.',
    'metrics-sidebar-description-threat-stats': 'Threat statistics and their breakdowns.',
    'metrics-sidebar-title-imports': 'Imports',
    'metrics-sidebar-title-threat-stats': 'Threat Stats',

    // New Threat Form
    'new-threat-add-elements-input-label': 'add elements',
    'new-threat-already-exists': 'Unable to save. Threat name already exists',
    'new-threat-cancel-button': 'Cancel',
    'new-threat-classification-label': 'Classification',
    'new-threat-classifications-placeholder': 'Add Classification(s)',
    'new-threat-comma-hint-label': '(comma separated)',
    'new-threat-creation-error': 'Error creating threat.',
    'new-threat-criticality-label': 'Criticality Score',
    'new-threat-description-label': 'Description',
    'new-threat-elements-label': 'Associated Elements',
    'new-threat-header': 'New Threat',
    'new-threat-input-label': 'Threat Name',
    'new-threat-metadata-label': 'Metadata',
    'new-threat-new-radio-label': 'New Threat',
    'new-threat-optional-hint-label': '(Optional)',
    'new-threat-save-button': 'Save',
    'new-threat-select-placeholder': 'Enter Threat',
    'new-threat-source-label': 'Source',
    'new-threat-source-tic-label': 'Source TIC Score',

    // No Results Found
    'no-results-found': 'No Results Found',
    'no-results-found-with-filters': 'No Results Found with Filters',

    // Notes
    'notes-empty': 'No Notes Found',
    'notes-editor-placeholder': 'Enter note here...',

    // Note Actions
    'note-actions-refresh': 'Refresh',

    // Note
    'note-last-updated': 'Last updated on {date} by {author}',
    'note-edit-link': 'Edit',
    'note-delete-link': 'Delete',
    'note-delete-note': 'Delete note?',
    'note-deleting-in-progress': 'Deleting note...',
    'note-deleting-complete': 'Deleted note',
    'note-delete-description':
      'Are you sure you want to delete this note? You cannot undo this action.',
    'note-unknown-user': 'Unknown',

    // Notes Form
    'note-form-author-add': 'Adding note as {author}',
    'note-form-author-edit': 'Editing note as {author}',
    'note-form-add-title': 'Add Note',
    'note-form-edit-note': 'Edit Note',
    'note-form-subject': 'Subject',
    'note-form-note': 'Note',
    'note-form-success': 'Added note to {parentType}',
    'note-form-edit-success': 'Edited note',

    // Org
    'org-loading': 'Organization Loading',
    'collection-limit-reached': 'Collection Limit Reached',
    'collection-limit-reached-warning':
      'You cannot add more collections as you have reached your limit.',
    'collection-limit-warning-dismiss': 'OK',
    'organization-collections': 'Collections:',
    'update-organization-title': 'Update Organization',
    'update-organization-collection-limit': 'Collection Limit',
    'update-organization-form-input-unlimited': 'Unlimited',
    'organization-loading': 'Loading...',

    // Port
    'port-error-message': 'An error has occurred.',

    // Remove Threat Association Form
    'remove-threat-assoc-form-subtitle': 'User-Defined Threat',
    'remove-threat-assoc-form-title': 'Remove Threat Association',
    'remove-threat-assoc-form-warning-message':
      'The association between {elementId} and {threatName} will be removed.',

    // Report
    'report-email-prompt': 'Your report will be emailed to {email}.',
    'report-email-confirm':
      'This report may take a few minutes to generate. The {reportName} Report will be emailed to you at {email}. Do you wish to continue?',
    'report-checking': 'Checking Report...',
    'report-config-form-report-type': 'Select a report type',
    'report-config-form-report-type-placeholder': 'Select Report',
    'report-config-form-scorecard-collection': 'Select Collection',
    'report-config-form-scorecard-collection-placeholder': 'Select collection',
    'report-config-form-summary-collections-criteria':
      'Select collections to include in the report',
    'report-config-form-summary-collections-criteria-by-tic': 'Collections by TIC',
    'report-config-form-summary-collections-criteria-association-count':
      'Collections by Threat Association Count',
    'report-config-form-summary-collections-criteria-assigned': 'Assigned',
    'report-config-form-summary-collections-criteria-custom': 'Custom',
    'report-config-form-summary-collections-criteria-by-tic-help':
      'Include collections in TIC descending order',
    'report-config-form-summary-collections-criteria-association-count-help':
      'Include collections in descending order by threat association count',
    'report-config-form-summary-collections-criteria-assigned-help':
      'Include collections assigned to the current user in TIC descending order',
    'report-config-form-summary-collections-criteria-custom-help':
      'Choose specific collections from this workspace.',
    'report-config-form-summary-collection-severity': 'Collection Severity',
    'report-config-form-summary-custom-collections-placeholder':
      'Type collection names to include in the report.',
    'report-config-form-summary-exclude-normal': 'Only include critical and elevated collections',
    'report-config-form-summary-help-limit':
      'In all cases the report is limited to the top 250 collections.',
    'report-config-form-threat-start-time': 'Select start time for the first report',
    'report-email-success': 'Report emailed successfully.',
    'report-error': 'Could not generate report. {message}',
    'report-not-available': 'This report is not yet available. Refresh to check again.',
    'report-not-found': 'This report does not exist.',
    'report-not-supported': 'Preview of this file type ({name}) is not currently supported.',
    'report-scorecard-too-large':
      'The Collection Scorecard report cannot be generated when a collection has more than 25,000 threat associations.',
    'report-too-large': 'Report Exceeds Association Limit',
    'report-running': 'Creating Report...',
    'report-history-actions': 'Actions',
    'report-history-table-header-name': 'Name',
    'report-history-table-header-schedule-name': 'Schedule Name',
    'report-history-table-header-date': 'Date',
    'report-history-table-header-type': 'Type',
    'report-history-title': 'Report History',
    'report-history-description': 'View the previous reports.',
    'report-run-title': 'Run Report',
    'report-run-description': 'Generate a new report.',
    'report-scheduled-description': 'Set up a report to run daily, weekly, or monthly.',
    'report-schedule-new': 'Schedule New Report',
    'report-schedule-not-found': 'Schedule not found.',
    'report-schedule-edit': 'Edit Scheduled Report',
    'report-scheduled-title': 'Scheduled Reports',
    'report-scheduled-delete': 'Delete',
    'report-edit-schedule': 'Edit',
    'report-section-title': 'Reports',
    'report-schedule-form-name': 'Schedule Name',
    'report-schedule-form-frequency-from': 'Starts on',
    'report-schedule-form-frequency-title': 'Frequency',
    'report-schedule-form-frequency-repeat': 'Repeat',
    'report-schedule-form-frequency-to': 'End',
    'report-schedule-form-recipients-title': 'Recipients',
    'report-schedule-form-recipients-input-text':
      'Send a copy of this report to the following recipients.',
    'report-schedule-form-end-occurences': 'occurences',
    'report-scheduled-table-header-name': 'Name',
    'report-scheduled-table-header-date': 'Date',
    'report-scheduled-table-header-type': 'Type',
    'report-scheduled-table-header-frequency': 'Frequency',
    'report-scheduled-table-header-history': 'History',
    'report-run-again': 'Run again',
    'report-recent-title': 'Recent Reports',
    'report-schedule-deleting-complete': 'Deleted scheduled report.',
    'report-deleting-complete': 'Deleted report.',
    'report-schedule-create-complete': 'Successfully created scheduled report.',
    'report-schedule-form-end-occurrences': 'occurrences',
    'report-schedule-unsaved-changes':
      'Are you sure you want to leave? All changes will be lost if you leave this page without saving.',
    'report-historical-reports-no-rows': 'No History',
    'report-scheduled-reports-no-rows': 'No Scheduled Reports',
    'report-delete-title': 'Delete Report?',
    'report-delete-description':
      'Are you sure you want to delete this report? You cannot undo this action.',

    // Search bar
    'search-input-placeholder': 'Search',

    // Search results
    'results-header-selected-title': '{selected} of {total} selected',
    'results-header-selected-clear': 'Clear selected',
    'search-whois-results-title': '{total} reverse whois results for ',
    'search-risks-results-title': '{total} associated risk results for ',

    'threat-history-crumb-title': 'All Associations',
    'threat-history-page-title': 'Association History',
    'threat-history-table-header-type-name': 'Risk',
    'ip-history-crumb-title': 'All Associations',
    'ip-history-page-title': 'Association History',
    'ip-history-table-header-type-name': 'Name',
    'ip-history-table-header-type': 'Type',
    'search-result-cvss': 'CVSS 2.0:',

    // Saved Search Form
    'saved-search-form-create-title': 'Save Search',
    'saved-search-form-edit-title': 'Edit Saved Search',
    'saved-search-form-expression': 'Expression',
    'saved-search-form-description': 'Description',
    'saved-search-form-success': 'Saved Search - {search}',

    // Search Filter
    'search-filter-name-placeholder': 'Name Contains',
    'search-filter-types-placeholder': 'Types',
    'search-filter-collection-select-placeholder': 'Collections',
    'search-filter-invalid-location-error': 'Invalid location. Please try again.',
    'search-filter-length-type-placeholder': 'Length',
    'search-filter-location-error': 'Error retrieving location. Please try again.',
    'search-filter-location-placeholder': 'Address',
    'search-filter-location': 'Location',
    'search-filter-owner-placeholder': 'Owner Contains',
    'search-filter-owners-select-placeholder': 'Owners',
    'search-filter-owners-no-options': 'Start typing to select owners...',
    'search-filter-radius-value-placeholder': 'Radius',
    'search-filter-sources-placeholder': 'Sources',
    'search-filter-sources-no-options': 'No Matching Sources',
    'search-filter-classifications-placeholder': 'Classifications',
    'search-filter-classifications-no-options': 'No Matching Classifications',
    'search-filter-advanced': 'Advanced',
    'search-filter-clear': 'Clear All',
    'search-filter-risk': 'Risk',
    'search-filter-terms-placeholder': 'Additional Terms',
    'search-filter-terms-help': '(Example: John Smith, test@gmail.com)',
    'search-filter-hash-placeholder': 'Associated to Hash',

    // Search Loader
    'search-loader-cancel': 'Cancel',
    'search-loader-query': 'Searching...{query}',
    'search-loader-paging': 'Going to page...{page}',
    'search-loader-limit': 'Setting results per page to...{limit}',
    'search-loader-sort-by': 'Sorting results by {sort} in {order} order',
    'search-loader-applying-filters': 'Applying filters...',
    'search-loader-clearing': 'Clearing filter...',

    // Search Table
    'search-table-clear-filters': 'Clear Filters',
    'search-table-header-fqdn': 'FQDN',
    'search-table-header-name': 'Name',
    'search-table-header-type': 'Type',
    'search-table-header-tic': 'TIC',
    'search-table-header-owner': 'Owners',
    'search-table-header-classifications': 'Classifications',
    'search-table-header-source': 'Source',
    'search-table-header-metadata': 'Metadata',

    // Search
    'search-map-disabled-radio-tooltip':
      'Narrow search to under 5000 results to enable Pin Map view',
    'search-maps-pinmap': 'Pin Map',

    // Sidebar
    'sidebar-workspace': 'Workspace',
    'sidebar-element-details': 'Element Details',
    'sidebar-collection-health': 'Collection Health',
    'sidebar-collection-management': 'Collection Management',
    'sidebar-incident-management': 'Incident Management',
    'sidebar-report': 'Reports',

    // Sort
    'sort-caption-template': 'Sort by {selectedSort}',
    'sort-name-asc': 'Name: A-Z',
    'sort-name-desc': 'Name: Z-A',
    'sort-tic-score-asc': 'TIC Score: Low to High',
    'sort-tic-score-desc': 'TIC Score: High to Low',
    'sort-source-asc': 'Source: A-Z',
    'sort-source-desc': 'Source: Z-A',

    // Threat Stats
    'threat-stats-feed-threat-classifications': 'Threat Classification Families',
    'threat-stats-feed-threat-severities': 'Threat TIC',
    'threat-stats-feeds': 'Feeds',
    'threat-stats-overview': 'Overview',

    // TIC Range slider
    'tic-range-slider-default': 'Reset',

    // Four Oh Four
    'twoOhTwo-title': 'Oops!',

    // About
    'about-build': 'System Version',
    'about-support': 'Support Email',
    'about-copyright':
      'Copyright © 2017-{currentYear}, LookingGlass Cyber Solutions, Inc. All rights reserved.',

    // Header
    'workspace-selector-placeholder': 'Select Workspace...',
    'workspace-actions-default': 'Default',
    'workspace-actions-set-default': 'Set As My Default',
    'workspace-actions-set-default-name': 'Set {name} as default workspace',
    'workspace-actions-setting-default': 'Setting default workspace...',
    'workspace-actions-edit': 'Edit',
    'workspace-actions-create': 'Create Workspace',
    'workspace-actions-delete': 'Delete',
    'workspace-actions-refresh': 'Refresh',
    'workspace-delete-success': 'Successfully deleted - {workspaceName}',
    'workspace-delete-title': 'Delete "{name}"?',
    'workspace-delete-description':
      'Are you sure you want to delete this workspace? You cannot undo this action.',
    'workspace-default': 'Default Workspace',
    'workspace-actions-default-already-set': '{name} is already set as your default workspace.',

    // Pagination
    'pagination-items-per-page': '/ page',
    'pagination-jump-to': 'Jump to page:',
    'pagination-jump-to-confirm': 'confirm',
    'pagination-page': '',
    'pagination-prev-page': 'Previous Page',
    'pagination-next-page': 'Next Page',
    'pagination-prev-5': 'Previous 5 Pages',
    'pagination-next-5': 'Next 5 Pages',
    'pagination-prev-3': 'Previous 3 Pages',
    'pagination-next-3': 'Next 3 Pages',
    'pagination-total-results-viewing': 'Viewing',
    'pagination-jump-to-max': 'The maximum pagination number to jump to is 20000',

    // Route Titles
    'route-title-about': 'About',
    'route-title-admin-groups': 'Groups - Admin',
    'route-title-admin-users': 'Users - Admin',
    'route-title-admin-workspaces': 'Workspaces - Admin',
    'route-title-api-docs': 'API Documentation',
    'route-title-association-add': 'New Threat Association',
    'route-title-association-remove': 'Remove Threat Association',
    'route-title-auth-convert': 'Resuming Session',
    'route-title-change-password': 'Change Password',
    'route-title-collection-management': 'Collection Management',
    'route-title-collection-management-collection': '{name} - Collection',
    'route-title-collection-health': 'Collection Health',
    'route-title-collection-include': 'Include into collection',
    'route-title-create-workspace': 'Create Workspace',
    'route-title-delete-workspace': 'Delete "{name}"',
    'route-title-edit-workspace': 'Edit "{name}"',
    'route-title-element-details': '{name} - Element',
    'route-title-forgot-password': 'Forgot Password',
    'route-title-incidents-add': 'Add Incident',
    'route-title-incident-management': 'Incident Management',
    'route-title-incident-management-folder': 'Folder - Incident Management',
    'route-title-incident-management-incident': 'Incident - Incident Management',
    'route-title-login': 'Login',
    'route-title-logout': 'Logout',
    'route-title-metrics-imports': 'Imports - Metrics',
    'route-title-metrics-threat-stats': 'Threat Stats - Metrics',
    'route-title-new-threat': 'New Threat',
    'route-title-note-add': 'Add Note',
    'route-title-note-delete': 'Delete Note',
    'route-title-note-edit': 'Edit Note',
    'route-title-notifications': 'Notifications',
    'route-title-notifications-log': 'Log - Notifications',
    'route-title-page-not-found': 'Page Not Found',
    'route-title-search': 'Search',
    'route-title-workspace-threat-associations': 'All Associations',
    'route-title-workspace-notes': 'Notes',
    'route-title-search-basic': 'Basic',
    'route-title-set-password': 'Set Your Password',
    'route-title-search-risks': 'Associated Risks',
    'route-title-search-whois': 'Reverse Whois',
    'route-title-threat-delete': 'Delete Threat',
    'route-title-tic-configuration': 'TIC Scoring',
    'route-title-token-create': 'Create API Access Token',
    'route-title-token-delete': 'Delete Token',
    'route-title-token-edit': 'Edit API Access Token',
    'route-title-tokens': 'API Access Tokens',
    'route-title-workspace-summary': 'Workspace Summary',
    'route-title-workspace-all-threats': 'All Threats',
    'route-title-workspace-all-elements': 'All Elements',
    'route-title-workspace-all-collections': 'All Collections',

    // Secret Value
    'secret-value-hide': 'Hide',
    'secret-value-show': 'Show',

    // Set Password
    'set-password-title': 'Set Your Password',
    'set-password-login': 'Return to Login',
    'set-password-no-token': 'There was no token provided in the url.',
    'set-password-error-title': 'The following error occurred:',
    'set-password-loading-indicator': 'Verifying token "{token}"',
    'set-password-invalid-token': 'This link is expired or invalid.',
    'set-password-submit-text': 'Save and Login',

    // Summary
    'summary-all-collections': 'All Collections',
    'summary-assoc-change-header': 'association count change',
    'summary-assoc-change-sub': '{change} {changeValue}% from last week',
    'summary-assoc-feed-header': 'Associations',
    'summary-assoc-feed-header-count': 'Associations ({count})',
    'summary-collections-panel-header': 'collections ({total})',
    'summary-daily-assoc-header': 'associations added today',
    'summary-element-panel-header': 'elements ({total})',
    'summary-my-collections': 'My Collections',
    'summary-notes-link': 'Notes ({notesCount})',
    'summary-panel-editor-title': 'Summary',
    'summary-panel-notes-title': 'Notes',
    'summary-association-cat-header': 'Associations by Risk Category',
    'summary-risk-panel-header': 'risks ({total})',
    'summary-weekly-assoc-header': 'new association count by day',
    'summary-view-all-collections': 'All Collections',
    'summary-view-all-associations': 'All Associations',

    // TIC Configuration
    'tic-configuration-title': 'Threat Indicator Confidence (TIC)',
    'tic-configuration-help': `
    Threat Indicator Confidence categorizes the network's threat potential into a single
    value. The system aggregates data from multiple feeds into threat risk assessments for
    network elements and related entities. TIC provides in-depth views into network topologies
    and traffic patterns, enabling users to drill down further for additional research, or to
    convert this intelligence into actionable strategies to protect their networks.
    `,
    'tic-configuration-sub-help': `
    The system assigned score ranging from
    {range} that categorizes the current threat risks associated with
    various system elements. Higher numeric values indicate a greater threat potential.
    `,

    // Token Form
    'token-delete-description':
      'Are you sure you want to delete this token? You cannot undo this action. The token will immediately no longer be valid for use with the API.',
    'token-delete-title': 'Delete Token?',
    'token-form-create-success': 'Created Token',
    'token-form-create-title': 'Create API Token',
    'token-form-description': 'Description',
    'token-form-description-placeholder':
      'A short description to indicate what this token is used for.',
    'token-form-edit-success': 'Saved Token',
    'token-form-edit-title': 'Edit API Token',
    'token-form-timeout': 'Timeout',
    'tokens-not-found': 'No tokens have been created yet.',

    // User Dropdown Menu
    'user-dropdown-about': 'About',
    'user-dropdown-admin': 'Admin',
    'user-dropdown-api': 'API Tokens',
    'user-dropdown-change-password': 'Change Password',
    'user-dropdown-tic-configuration': 'TIC Configuration',
    'user-dropdown-help': 'Help',
    'user-dropdown-feedback': 'Feedback',
    'user-dropdown-logout': 'Logout',

    // Groups
    'permissions-permission-type': 'Permission Type',

    // Workspace
    'workspace-404-context': 'The workspace you are looking for does not exist.',
    'workspace-loading': 'Loading Workspace...',
    'workspace-not-found': 'You do not have access to {workspaceName}. Workspace has been changed.',
    'workspace-summary-title': 'Summary',
    'workspace-summary-saved': 'Successfully saved workspace summary',
    'workspace-summary-placeholder': 'Enter summary here...',
    'workspace-summary-double-click': 'Double-click to edit',
    'workspace-summary-char-limit': 'Character Limit',
    'workspaces-loading': 'Loading Workspaces...',

    // Workspace Form
    'workspace-form-create-title': 'Create Workspace',
    'workspace-form-edit-title': 'Edit Workspace',
    'workspace-form-name': 'Name',
    'workspace-form-description': 'Description',
    'workspace-form-create-success': 'Created workspace - {name}',

    // Email "Collection Scorecard" form
    'user-autocomplete-title': 'Send a link to the report to the following recipient(s).',
    'user-autocomplete-optional': '(Comma separated)',
    'user-autocomplete-invalid-error': 'Some recipients are invalid.',
    'email-report-text': 'Email Report',
    'email-report-title': 'Email "Collection Scorecard"',

    // Threat Description Form
    'threat-description-placeholder': 'Press edit to add a description.',
    'threat-description-placeholder-editing': 'Enter description here.',
    'threat-saving': 'Saving...',

    // Element details
    'element-details-labels': 'Labels',
    'element-details-section-display-error': 'Failed to load the section.',
    'active-threat-no-elements': 'No elements',
    'active-threat-table-header-element': 'Element',
    'active-threat-table-header-tic': 'Tic',
    'active-threat-table-header-last-seen': 'Last Seen',
    'active-threat-table-header-risk': 'Risk',
    'active-threat-table-header-type': 'Type',

    // User Page
    'user-sidebar-description-api': 'Manage API access tokens.',
    'user-sidebar-title-api': 'API Tokens',

    // User Page - API
    'user-api-token-expired': 'Expired:',
    'user-api-token-expiration': 'Expires:',
    'user-api-token-help-blurb':
      'Use these tokens as a Bearer token in an HTTP Authorization header for API automation use cases.',
    'user-api-token-last-used': 'Last Used:',
    'user-api-token-timeout': 'Timeout:',

    // Notifications
    'collection-notifications-manage': 'Manage Notifications',
    'notification-event-tic-greater-than-text':
      'TIC score has increased from {originalTicScore} to {ticScore}',
    'notification-event-added-to-collection': 'has a TIC score of {ticScore}',
    'notification-create-label': 'New Notification',
    'notification-logs-table-header-date': 'Date',
    'notification-logs-table-header-collection': 'Collections',
    'notification-logs-table-header-event': 'Event',
    'notification-logs-table-header-notification': 'Notification Name',
    'notification-logs-table-no-data': 'No Notification Logs',
    'notification-rule-not-found': 'This notification does not exist',
    'notification-rule-create-success': 'Successfully created notification.',
    'notification-rule-delete-description':
      'Are you sure you want to delete this notification? You cannot undo this action.',
    'notification-rule-deleted-success': 'Successfully deleted notification.',
    'notification-rule-delete-title': 'Delete Notification?',
    'notification-rule-updated-success': 'Successfully updated notification.',
    'notification-table-header-collection-tic': 'Collection TIC',
    'notification-table-header-element-tic': 'Element TIC',
    'notification-table-header-frequency': 'Frequency',
    'notification-table-header-name': 'Name',
    'notification-table-header-notification-type': 'Notification Type',
    'notification-table-header-source-ignore': 'Source Ignore',
    'notification-table-header-threat-ignore': 'Threat Ignore',
    'notification-table-no-data': 'No Notifications configured',
    'notification-update-label': 'Update Notification',
    'notifications-form-alert-type': 'Alert Preference',
    'notifications-form-collection-id': 'Select Collection',
    'notifications-form-collection-notification-type': 'Notify when Collection TIC is greater than',
    'notifications-form-email-frequency': 'Email Frequency',
    'notifications-form-element-notification-type': 'Notify when Element TIC is greater than',
    'notifications-form-field-alert-type-email': 'Email',
    'notifications-form-field-sub-alert-type-details': 'Additional Details',
    'notifications-form-field-sub-alert-type-separate':
      'Send separate email for each element over the threshold',
    'notifications-form-ignore-sources': 'Ignore Sources',
    'notifications-form-ignore-threats': 'Ignore Threats',
    'notifications-form-name': 'Name',
    'notifications-form-recipients-title': 'Recipients',
    'notifications-form-recipients-input-text': 'Admin: Send Notifications to System Users',
    'notifications-form-threat-notification-type': 'Notify when there is a new threat association',
    'notifications-form-vulnerability-notification-type':
      'Notify when there is a new vulnerability association',
    'notifications-form-type': 'Notification Type',
    'notifications-list-description': 'Create, edit and delete notifications.',
    'notifications-log-description': 'View all notifications triggered by set parameters.',
    'notifications-section-title': 'Settings',

    // CPE
    'cpe-name': 'CPE NAME',
    'cpe-part': 'PART',
    'cpe-vendor': 'VENDOR',
    'cpe-product': 'PRODUCT',
    'cpe-version': 'VERSION',

    // Associations Page
    'associations-active-elements': 'Active Elements',
    'associations-associations': 'Active Associations',
    'associations-cvss-title': 'CVSS 2.0',

    // Active Associations Panel
    'active-association-risks': 'Risks',
    'active-association-elements': 'Elements',
    'active-association-threat-history': 'Threat History',
    'active-association-history': 'History',
    'active-association-element-history': 'Element History',

    // Vulnerability
    'vulnerability-properties-classification': 'Classification',
    'vulnerability-properties-criticality': 'Criticality Name',
    'vulnerability-properties-tic': 'Criticality TIC',
    'vulnerability-properties-edit-title': 'Vulnerability Properties',
    'edit-vulnerability-property-tic-score': 'Edit VULNERABILITY Property TIC Score',

    'threat-properties-edit-title': 'Threat Properties',
    'edit-threat-property-tic-score': 'Edit Threat Property TIC Score',
  },
};
