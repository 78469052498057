export default theme => ({
  content: {
    backgroundColor: theme.colors.white,
    height: 'calc(100% - 60px)', // minus the height of the app's header
    position: 'relative',
    width: '100%',
    flexGrow: 1,
    flexDirection: 'row-reverse',
    overflow: 'hidden',
    display: 'flex',
  },
  blur: {
    filter: 'blur(2px)',
  },
});
