import React from 'react';

import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { loginError } from '../../../redux/modules/auth/actions';

class OktaError extends React.Component {
  componentDidMount() {
    const { error, loginError } = this.props;
    loginError(error);
  }
  render() {
    return <Redirect to="/login" />;
  }
}

const mapDispatchToProps = {
  loginError,
};

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(OktaError)
);
