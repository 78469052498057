import React from 'react';
import { injectIntl } from 'react-intl';

// Components
import { Route, Switch, withRouter } from 'react-router-dom';

import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security, withOktaAuth } from '@okta/okta-react';
import Callback from './Callback';

// Redux
import { connect } from 'react-redux';
import {
  initOauth2,
  loginError,
  loginStop,
  convertTokenError,
  convertTokenUpdate,
} from '../../../redux/modules/auth/actions';
import { refreshOrgUsers } from '../../../redux/modules/entities/actions';

// API
import * as usersApi from '../../../api/users';
// Utils
import { get, isBoolean, isNil, isEqual } from 'lodash';
import { oktaIdNonce, setOauth2AuthToken, setOauth2IdToken } from '../../../lib/session';
import notification from '../notification';
import { isTokenValid } from '../../../utils/auth.js';

const OKTA_LOGOUT_REDIRECT = '/login?sp';

const MAX_TRIES = 10;
// it's crazy, but if this wait is too fast, the okta library reports the user is
// unauthenticated, keep this at least 1s
const RETRY_WAIT_MS = 1000;

class OktaLifecycle extends React.Component {
  constructor(props) {
    super(props);
    this.state = { tries: 0 };
  }

  // Over time this function has been changed to preform less work, and now sets cookies
  // (which are not currently used internally to SP) and sets still gets user data (see note
  // in onUser() function)
  establishSession = async () => {
    const { oktaAuth, onUser, tokenConfig } = this.props;
    const accessToken = oktaAuth.getAccessToken();
    const idToken = oktaAuth.getIdToken();
    // set scoutPRIME token cookies (though they are not used internally, they are set for possible compatability purposes).
    setOauth2AuthToken(accessToken, tokenConfig);
    setOauth2IdToken(idToken, tokenConfig);
    const user = await oktaAuth.getUser();
    if (onUser) {
      onUser(user);
    }
    // The sessionToken is setup in Convert.js, see resumeSession() and does not need be set up again here
    // In fact, it appears to cause problems if updated here.
  };

  /**
   * Sometimes the Okta component returns `null` as the authenticated value. It's unclear
   * why, but perhaps due to not being fully initialized yet. This checks that the
   * value is something we can action.
   *
   * @param authenticated
   * @returns {boolean}
   */
  isValidAuthenticatedValue = authenticated => {
    return isBoolean(authenticated);
  };

  canRetry = () => {
    return this.state.tries < MAX_TRIES;
  };

  /**
   * Triggers authentication checkage again after a certain amount of waiting. Records
   * the number of tries that have occurred.
   */
  retryCheckAuthentication = () => {
    this.setState(
      state => {
        return { ...state, tries: state.tries + 1 };
      },
      () => {
        setTimeout(this.checkAuthentication, RETRY_WAIT_MS);
      }
    );
  };

  checkAuthentication = async () => {
    console.log('Checking authentication against Okta.');
    // eslint-disable-next-line
    const { loginStop, onLoginError, authState } = this.props;
    const authenticated = authState.isAuthenticated;
    if (authState.error) {
      loginError(authState.error);
      return;
    }

    // hardening around okta giving us what we want (and can use)
    if (!this.isValidAuthenticatedValue(authenticated) && this.canRetry()) {
      this.retryCheckAuthentication();
      return;
    }

    switch (authenticated) {
      case true:
        try {
          await this.establishSession();
          // need to stop logging in so that the login process can continue by way of
          // converting the cookie just set to a real session
          loginStop();
        } catch (error) {
          // this means that Okta logged the user in, but there was an issue with SP
          // accepting the user as being logged in
          onLoginError(error);
        }
        break;
      case false:
        console.log('Not authenticated according to Okta.');
        loginStop();
        break;
      default:
        // okta returned an unsupported value after multiple retries, bail out back to
        // the login screen via changing the app state's isLoggingIn status
        loginStop();
    }
  };

  /**
   * This handler is triggered when the Okta access token is renewed, which is configured
   * to be done automatically 30 seconds before it expires (in devMode this can be changed,
   * see expireEarlySeconds).
   *
   * This will keep the scoutPRIME x-lg-authorization and x-lg-id cookies in sync with
   * renewed Okta Access and ID tokens (which is stored in local storage okta-token-storage by Okta component/package).
   * Internally these cookies are not used but are maintained for possible compatability purposes.
   *
   * In oauth2 auth mode, redux is no longer used, with one exception. During intialization of a user being signed in,
   * (see resumeSession() in Convert.js) the sessionToken in Redux is used for some initializaiton tasks, including
   * establishing a session with the scoutPRIME server. After a user is signed in and the user's session is initialized
   * (which can be detected by `sessionToken` existing in Redux), sessionToken's Access and ID tokens are retrieved from
   * Okta (see getSessionToken() below). In oauth2 auth mode, a reference to getSessionToken() is stored in Redux for use
   * throughout sp-web-ui code by determineTokenAndAuth in withSessionToken.js and in tokenThunk() (in api.js).
   */
  renewTokenHandler = (key, newToken, oldToken) => {
    const { oktaAuth, tokenConfig, convertTokenError, convertTokenUpdate } = this.props;
    let accessToken = null;
    let idToken = null;
    let validTokens = true; // assume the access and id tokens are valid, if not this value will be changed below

    if (key === 'accessToken') {
      idToken = oktaAuth.getIdToken(); // get existing (used for updatedSessionToken below)
      accessToken = newToken.accessToken;
      if (isTokenValid(accessToken, 'renew - new Access Token')) {
        setOauth2AuthToken(accessToken, tokenConfig); // update with new access token in cookie
        console.log("Access token has been renewed and sync'd.");
      } else {
        console.error('Problem renewing Access Token.');
        validTokens = false;
      }
      // also validate retrieved existing idToken
      if (!isTokenValid(idToken, 'renew - existing idToken')) {
        validTokens = false;
      }
    } else if (key === 'idToken') {
      // (ID tokens are currently set to a fixed expiration of 1 hour by Okta.)
      accessToken = oktaAuth.getAccessToken(); // get existing access token (used for updatedSessionToken below)
      idToken = newToken.idToken;
      if (isTokenValid(idToken, 'renew - new ID Token')) {
        setOauth2IdToken(idToken, tokenConfig); // update with new ID token in cookie
        console.log("ID token has been renewed and sync'd.");
      } else {
        console.error('Problem renewing ID Token.');
        validTokens = false;
      }
      // also validate retrieved existing idToken
      if (!isTokenValid(accessToken, 'renew - existing accessToken')) {
        validTokens = false;
      }
    } else if (key === 'refreshToken') {
      // Currently no additional sync needed for Refresh token (only used by Okta component/package).
      console.log('Refresh token has been renewed.');
      return;
    } else {
      // Currently no additional sync needed, since it appears that access, id or refresh tokens are not being renewed.
      // (This is a just in case catch-all, there are no other known tokens besides Access, ID & Refresh.)
      console.log('Token with key of ' + key + ' is being renewed.');
      return;
    }

    if (validTokens) {
      // Invalid tokens are used below so that if the sessionToken is by accident used directly from Redux instead of using
      // withSessionToken or tokenThunk, an error will occur (see additional explanation at top of this function).
      try {
        // The session is initially setup in Convert.js, see resumeSession()
        const updatedSessionToken = {
          domain: process.env.REACT_APP_SESSION_DOMAIN || tokenConfig.domain,
          id: 'Token no longer needed. ID token only needed in Redux during initialization.', //idToken,
          type: 'oauth2',
          value: 'Token no longer needed. Access token only needed in Redux during initialization.', //accessToken,
        };
        convertTokenUpdate(updatedSessionToken);
      } catch (err) {
        convertTokenError(err);
      }
    } else {
      // if actual tokens are not valid display error (we don't want to do any Redux update in this case)
      console.error('Problem renewing and syncing Access or ID Token.');
    }
  };

  async componentDidMount() {
    const { oktaAuth, authState } = this.props;
    console.log(
      'Mounting OktaLifecycle component' +
        (this.props.authState.isAuthenticated
          ? ' (authenticated by Okta)'
          : ' (not authenticated by Okta)')
    ); // for debug

    oktaAuth.tokenManager.on('renewed', this.renewTokenHandler);

    if (authState.isAuthenticated) {
      await this.checkAuthentication();
    }
  }

  componentWillUnmount() {
    console.log(
      'Unmounting OktaLifecycle component' +
        (this.props.authState.isAuthenticated
          ? ' (authenticated by Okta)'
          : ' (not authenticated by Okta)')
    ); // for debug
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.authState.isAuthenticated &&
      !isEqual(this.props.authState.isAuthenticated, prevProps.authState.isAuthenticated)
    ) {
      await this.checkAuthentication();
    }
  }

  render() {
    return null;
  }
}

const OktaAuthLifecycle = withOktaAuth(injectIntl(OktaLifecycle));

// OktaSecurity
// ============

function isSupported(oauth2Config) {
  // it's supported if we have all the necessary values to use it
  return oauth2Config && oauth2Config.clientId && oauth2Config.issuer;
}

function isFieldUnset(value) {
  return isNil(value) || value === '';
}

/**
 * Compares two values for the same field and returns true if they're different enough to
 * warrant an update. This is useful for when the values are not set, as there's no
 * guarantee that either platform is consistently returning null, undefined, or an empty
 * string.
 *
 * This is geared toward simple values, data structures like objects or arrays may end
 * up in a weird state.
 *
 * @param spValue
 * @param oktaValue
 */
function fieldNeedsUpdate(spValue, oktaValue) {
  if (isFieldUnset(spValue) && isFieldUnset(oktaValue)) {
    // the old and the new value are equivalently "unset", even if they're not the same
    // precise value
    return false;
  }
  return spValue !== oktaValue;
}

class OktaSecurity extends React.Component {
  state = {
    oktaUser: null,
  };

  oktaAuth = null;
  login = null;
  logout = null;
  getSessionToken = null;

  // profileSyncChecked is used to avoid repeating too many (possible infinate) profile updates.
  // When the Okta person profile is updated for a signed in user and then the browser is refreshed (or under
  // normal operation the OktaSecurity gets re-rendered or is remounted), oktaAuth.getUser() will reflect the changes,
  // but the Access and ID tokens are not renewed at that same time...but later when the tokens are automatcially
  // renewed 30 seconds before they expire.
  // To reduce unnecessary/repeated attempts to sync a user profile with the SP server, the same data now is used to check
  // if a sync is needed as well as updating the profile data on the SP server.
  // Currently. idToken is used to check if sync is needed as well as to actual update (sync) data on scoutPRIME server.
  // Due to profileSyncChecked, user profile will sync upon sign in (and possibly when the idToken is updated if profile is changed).
  // This variable could possible be removed if sync check AND update are changed to changed to use oktaAuth.getUser().
  profileSyncChecked = false;

  // Though oktaUser is not currently used, it remains for future use if/when it is used in profileNeedsUpdate() and
  // syncProfile() to actually update data on the SP server (to do so will require updates to the SP server code).
  onUser = oktaUser => {
    this.setState({ oktaUser });
  };

  oauth2Config = () => {
    const { config } = this.props;

    const isOauth2Configured = get(config, 'data.oauth2');
    if (!isOauth2Configured) {
      return;
    }

    const pkceEnabled =
      process.env.REACT_APP_OAUTH2_PKCE_ENABLED === undefined
        ? get(config, 'data.oauth2.pkceEnabled')
        : process.env.REACT_APP_OAUTH2_PKCE_ENABLED === 'true';
    const devMode =
      process.env.REACT_APP_OAUTH2_DEV === undefined
        ? get(config, 'data.oauth2.devMode')
        : process.env.REACT_APP_OAUTH2_DEV === 'true';

    const clientId = process.env.REACT_APP_OAUTH2_CLIENT_ID || get(config, 'data.oauth2.clientId');
    const issuerUrl = process.env.REACT_APP_OAUTH2_ISSUER || get(config, 'data.oauth2.issuerUrl');

    if (!clientId || !issuerUrl) {
      return;
    }
    return {
      clientId: clientId,
      issuer: issuerUrl,
      redirectUri: window.location.origin + '/login/callback',
      scopes: ['openid', 'profile', 'email', 'offline_access'], // Importnat! offline_access is required for refresh token use
      pkce: pkceEnabled, //if pkceEnabled is undefined or true pkce is enabled (default)
      tokenManager: {
        storage: 'localStorage', // 'sessionStorage' (single tab - default), 'localStorage' (cross tab), 'cookie' (cross tab & server)
        // currently 'localStorage' seems to be working better than 'sessionStorage', possibly because when
        // a user has multiple tabs open for an instance of SP, there is just on copy of Okta tokens at any
        // give time (though they might be being renewed by each tab).
        // autoRenew: true, //(default is true)
        // expireEarlySeconds: 120, //(default is 30 only changable in devMode)
      },
      devMode: devMode, //FOR DEBUG ONLY
    };
  };

  profileNeedsUpdate = () => {
    const { user } = this.props;
    if (!user) {
      return false;
    }
    // The idToken is one option to use the same object to check if syncProfile() is needed as what is sent.
    if (!this.oktaAuth) {
      console.log('Okta auth data not ready yet.'); // for debug
      return false;
    }
    const idToken = this.oktaAuth.getIdToken();
    if (!idToken) {
      console.log('ID token not ready yet.'); // for debug
      return false;
    }
    const decodedIdToken = this.oktaAuth.token.decode(idToken);
    if (!decodedIdToken || !decodedIdToken.payload) {
      console.log('ID token payload not ready yet.'); // for debug
      return false;
    }
    // The oktaUser is another option to use the same object to check if syncProfile() is needed as what is sent.
    const { oktaUser } = this.state;
    if (!oktaUser) {
      console.log('Okta user data not ready yet.'); // for debug
      return false;
    }
    console.log('Okta authentication data is now ready.'); // for debug
    this.profileSyncChecked = true;

    // Using the same element (idToken) to check if need to sync user profile as is used
    // to sync user profile with the SP server (Alternative noted below).
    return (
      fieldNeedsUpdate(user['email-address'], decodedIdToken.payload.email) ||
      fieldNeedsUpdate(user['full-name'], decodedIdToken.payload.fullname) ||
      fieldNeedsUpdate(user['phone-number'], decodedIdToken.payload.phone) ||
      fieldNeedsUpdate(user['session-duration-ms'], decodedIdToken.payload.timeout)
    );

    // To be more responsive the oktaUser could be used also to send to the scoutPRIME
    // server (which is not currently the case). This is because its copy of the
    // user's profile data is updated upon refresh (reinstatiation of oktaAuth)
    // where as the token data appears to only update on renewal. But, to use this
    // correctly syncProfile() should be changed to also use oktaUser's profile data
    // instead of profile data from the id token (which it uses currently), and would
    // require a change to scoutPRIME server code.
    //
    // return (
    //   fieldNeedsUpdate(user['email-address'], oktaUser.email) ||
    //   fieldNeedsUpdate(user['full-name'], oktaUser.fullname) ||
    //   fieldNeedsUpdate(user['phone-number'], oktaUser.phone) ||
    //   fieldNeedsUpdate(user['session-duration-ms'], oktaUser.timeout)
    // );
  };

  tokenConfig = oauth2Config => {
    const { config } = this.props;
    const { pkce } = oauth2Config || { pkce: false };
    const domain = process.env.REACT_APP_SESSION_DOMAIN || get(config, 'data.oauth2.domain');
    const tokenConfig = { type: 'oauth2', secure: pkce };
    if (domain) {
      tokenConfig.domain = domain;
    }
    return tokenConfig;
  };

  /**
   * Synchronizes the profile info from the user's id token with the server. When done,
   * refreshes the users in the app state to make sure the most recent info is there.
   *
   * This isn't a security thing, and only happens on initial session conversion (since
   * that's when the user info is available from the react widget). So if it errors, it's
   * not a huge issue.
   *
   * syncProfile should be called after this.getSessionToken has been set.
   * this.getSessionToken() should be used directly (not from Redux), since it is defined in
   * this file already and to avoid any circular ref.
   */

  syncProfile = async () => {
    const { refreshOrgUsers, userOrganizationId } = this.props;
    if (this.getSessionToken) {
      try {
        let curSessionToken = this.getSessionToken();
        await usersApi.syncProfile(curSessionToken, oktaIdNonce(), curSessionToken.id);
        console.log("Sync'd profile data"); // for debug
        refreshOrgUsers(userOrganizationId);
      } catch (error) {
        console.error('Could not sync profile.', error);
        notification.error(`Could not sync profile.`);
      }
    } else {
      console.error(
        'Attempting to sync user profile, but Okta session has not yet been established.'
      );
    }
  };

  setupOktaAuth = oauth2Config => {
    if (isSupported(oauth2Config)) {
      if (!this.oktaAuth) {
        console.log('Setting up Okta auth.');

        this.oktaAuth = new OktaAuth(oauth2Config);

        this.login = async () => this.oktaAuth.signInWithRedirect();

        this.logout = async (pathname = OKTA_LOGOUT_REDIRECT) => {
          try {
            await this.oktaAuth.signOut({
              postLogoutRedirectUri: window.location.origin + pathname,
            });
          } catch (err) {
            if (err.message === undefined) {
              // add error message to be used when error is displayed
              err.message = this.props.intl.formatMessage({
                id: 'auth-logout-error',
                defaultMessage: 'An error occurred while logging out.',
              });
            }
            throw err;
          }
        };

        this.isAuthenticated = () => {
          let isAuthenticated = this.oktaAuth.authStateManager._authState.isAuthenticated;
          return isAuthenticated;
        };

        this.getSessionToken = () => {
          // Important: getSessionToken() is used in withSessionToken which delivers sessionToken,
          // thus sessionToken from withSessionToken should not be used here
          let curSessionToken = null;
          if (this.oktaAuth) {
            // get current tokens from Okta rather than from a Redux copy
            let accessToken = this.oktaAuth.getAccessToken(); // get existing access token (used for curSessionToken below)
            let idToken = this.oktaAuth.getIdToken(); // get existing (used for curSessionToken below)

            // check if Access and ID tokens valid (should never be expired due to Okta auto-renewal)
            if (
              isTokenValid(accessToken, 'Okta getSessionToken - Access token', false) &&
              isTokenValid(idToken, 'Okta getSessionToken - ID token', false)
            ) {
              // The session is initially setup in Convert.js, see resumeSession()
              curSessionToken = {
                domain:
                  process.env.REACT_APP_SESSION_DOMAIN ||
                  get(this.props.config, 'data.oauth2.domain'),
                id: idToken,
                type: 'oauth2',
                value: accessToken,
              };
            } else {
              if (this.oktaAuth.authStateManager._authState.isAuthenticated) {
                console.log('Problem getting current/valid Access or ID Token.');
              } else {
                console.log(
                  'Cannot get valid token(s) because user is not yet authenticated or authentication needs to be reestablished.'
                );
              }
            }
          }
          return curSessionToken;
        };

        this.props.initOauth2(this.login, this.logout, this.getSessionToken, this.isAuthenticated);
      } else {
        //console.log("Okta auth has already been setup.");
      }
    } else {
      console.log('Okta configuration is not yet available.');
    }
  };

  // Important! If componentDidMount, componentWillUnmount, etc. are used,
  // include isSupported(oauth2Config) checks (see use in componentDidUpdate()).

  componentDidUpdate(prevProps, prevState, snapshot) {
    const oauth2Config = this.oauth2Config();
    if (!isSupported(oauth2Config)) {
      return null;
    }

    if (!this.profileSyncChecked && this.profileNeedsUpdate()) {
      this.syncProfile();
    }
  }

  restoreOriginalUri = async (_oktaAuth, originalUri) => {
    this.props.history.replace(toRelativeUrl(originalUri, window.location.origin));
  };

  render() {
    const {
      isLoggingIn,
      loginStop,
      onLoginError,
      convertTokenError,
      convertTokenUpdate,
    } = this.props;
    const oauth2Config = this.oauth2Config();
    if (!isSupported(oauth2Config)) {
      return null;
    }

    // called here in render due to the fact that needed elements are not ready in componentDidMount()
    this.setupOktaAuth(oauth2Config);

    return (
      <Security oktaAuth={this.oktaAuth} restoreOriginalUri={this.restoreOriginalUri}>
        <Switch>
          <Route path="/login/callback" component={Callback} />
        </Switch>
        <OktaAuthLifecycle
          isLoggingIn={isLoggingIn}
          loginStop={loginStop}
          onLoginError={onLoginError}
          onUser={this.onUser}
          tokenConfig={this.tokenConfig(oauth2Config)}
          convertTokenError={convertTokenError}
          convertTokenUpdate={convertTokenUpdate}
        />
      </Security>
    );
  }
}

const mapStateToProps = ({ auth: { config, isLoggingIn }, entities, user }) => ({
  config,
  isLoggingIn,
  user: get(entities, ['users', 'data', user.userId]),
  userOrganizationId: user.organizationId,
});

const mapDispatchToProps = {
  initOauth2,
  loginStop,
  onLoginError: loginError,
  refreshOrgUsers,
  convertTokenError,
  convertTokenUpdate,
};

// IMPORTANT! withSessionToken should NOT be used here. To get sessionToken use this.getSessionToken() directly (not from Redux),
// since it is defined in this file already and to avoid any circular ref.
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(injectIntl(OktaSecurity))
);
