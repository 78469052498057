import { concat, get, toInteger } from 'lodash';
import { intToIpv4, ipv4RangeToCidrv4 } from './network';
import multimethod from '../lib/multimethod';
import * as immutable from '../lib/immutable';
import set from 'lodash/fp/set';
import * as IPv6Utils from 'ip-num/IPv6Utils';
import { Validator } from 'ip-num/Validator';

export const MAX_32BIT_ASN = 4294967295;

/**
 * Filters out system collections and collections the user cannot navigate to because there's no workspace entry
 * for them.
 *
 * @param element
 * @param currentWorkspace
 * @param workspaces
 * @returns {*}
 */
export function safeCollections(element, currentWorkspace, workspaces) {
  const elementCollections = get(element, 'collections', []).filter(
    collection => collection.collectionType !== 'system'
  );
  const workspaceCollections = elementCollections.filter(collection => {
    if (!currentWorkspace || !workspaces) {
      return false;
    }
    const collectionWorkspaceId = collection.workspaceId;
    return workspaces[collectionWorkspaceId];
  });
  return set('collections', workspaceCollections, element);
}

/**
 * Returns whether the `asn` is an integer and within the valid range of Autonomous Systems
 * Covers up to 32-bit based ASNs
 * i.e 0 - MAX_32BIT_ASN
 * @param possibleAsn
 * @returns {boolean}
 */
export const isAsn = possibleAsn => {
  const asn = toInteger(possibleAsn);
  if (isNaN(asn) || String(asn) !== String(possibleAsn)) {
    // the number was either NaN, or it came back different than it was originally (meaning
    // some sort of coercion happened)
    return false;
  }
  return asn >= 0 && asn <= MAX_32BIT_ASN;
};

const ipv4Segment =
  '(?:25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]\\d|\\d)(?:\\.(?:25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]\\d|\\d)){3}';

/**
 * Regex expression for an ipv4 element
 * @type {RegExp}
 */
export const regexIpv4 = new RegExp(`^${ipv4Segment}$`);

/**
 * Regex expression for a cidrv4 element
 * @type {RegExp}
 */
export const regexCidrv4 = new RegExp(`^${ipv4Segment}/(3[0-2]|[12]?[0-9])$`);

/**
 * Returns whether the `cidrv4` matches the structure of a Classless Inter-Domain Routing element, <x.x.x.x>/<mask>
 * @param cidrv4
 * @returns {boolean}
 */
export const isCidrv4 = cidrv4 => regexCidrv4.test(cidrv4);

/**
 * Returns whether the `cidrv6` matches the structure of a Classless Inter-Domain Routing element
 * @param cidrv6
 * @returns {boolean}
 */
export const isCidrv6 = cidrv6 => Validator.isValidIPv6CidrRange(cidrv6)[0];

/**
 * Regex expression for a fqdn element
 * @type {RegExp}
 */
export const regexFqdn = /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/;

/**
 * Returns whether the `fqdn` matches the structure of a Fully Qualified Domain Name
 * @param fqdn
 * @returns {boolean}
 */
export const isFqdn = fqdn => regexFqdn.test(fqdn);

/**
 * Returns whether the `ipv4` matches the structure of a Internet Protocol version 4 element
 * @param ipv4
 * @returns {boolean}
 */
export const isIpv4 = ipv4 => regexIpv4.test(ipv4);

/**
 * Returns whether the `ipv6` matches the structure of a Internet Protocol version 4 element
 * @param ipv6
 * @returns {boolean}
 */
export const isIpv6 = ipv6 => Validator.isValidIPv6String(ipv6)[0];

/**
 * Returns compressed form of `ipv6`. SP stores ipv6 in short forms.
 * @param ipv6
 * @returns {IPV6}
 */
export const compressedIpv6 = ipv6 =>
  IPv6Utils.collapseIPv6Number(IPv6Utils.expandIPv6Number(ipv6)).toLowerCase();

/**
 * Returns expanded form of `ipv6`.
 * @param ipv6
 * @returns Expanded {IPV6}
 */
export const expandedIpv6 = ipv6 => IPv6Utils.expandIPv6Number(ipv6);

/**
 * Regex expression for a md5 element
 * 32 hex chars
 * @type {RegExp}
 */
export const regexMD5 = /^[a-f0-9]{32}$/i;

/**
 * Returns whether the `md5` matches the structure of an md5 hash
 * @param md5
 * @returns {boolean}
 */
export const isMD5 = md5 => regexMD5.test(md5);

/**
 * Regex expression for a sha1 element
 * 40 hex chars
 * @type {RegExp}
 */
export const regexSHA1 = /^[a-f0-9]{40}$/i;

/**
 * Returns whether the `sha1` matches the structure of an sha1 hash
 * @param sha1
 * @returns {boolean}
 */
export const isSHA1 = sha1 => regexSHA1.test(sha1);

/**
 * Regex expression for a sha256 element
 * 64 hex chars
 * @type {RegExp}
 */
export const regexSHA256 = /^[a-f0-9]{64}$/i;

/**
 * Returns whether the `sha256` matches the structure of an sha256 hash
 * @param sha256
 * @returns {boolean}
 */
export const isSHA256 = sha256 => regexSHA256.test(sha256);

/**
 * Regex expression for a sha512 element
 * 128 hex chars
 * @type {RegExp}
 */
export const regexSHA512 = /^[a-f0-9]{128}$/i;

/**
 * Returns whether the `sha512` matches the structure of an sha512 hash
 * @param sha512
 * @returns {boolean}
 */
export const isSHA512 = sha512 => regexSHA512.test(sha512);
/**
 * List of element types used across the app.
 */

export const astBaseTypes = ['asn', 'cidrv4', 'cidrv6', 'fqdn', 'ipv4', 'ipv6'];
export const astPlusOwnerTypes = [...astBaseTypes, 'owner'];
export const astPlusThreatTypes = [...astPlusOwnerTypes, 'threat'];

/**
 * Returns whether `hash` is md5, sha1, sha256, or sha512
 * @param hash
 * @returns {boolean}
 */
export const isAnyHash = hash => isMD5(hash) || isSHA1(hash) || isSHA256(hash) || isSHA512(hash);

export const regexPattern = (pattern, flags) => new RegExp(pattern, flags);

function hashesByAlg(element, alg) {
  return get(element, `${alg}s`, []).map(h => {
    return { alg, hash: h };
  });
}

export function elementToHashes(element) {
  return concat(
    hashesByAlg(element, 'md5'),
    hashesByAlg(element, 'sha1'),
    hashesByAlg(element, 'sha256'),
    hashesByAlg(element, 'sha512')
  );
}

export function makeElement(type, id) {
  return {
    name: id,
    ref: {
      id,
      type,
    },
  };
}

export const internalizeRef = multimethod('type', element => element);

internalizeRef.method.asn = element => immutable.update(element, 'id', id => id.toString());

internalizeRef.method.cidrv4 = element => immutable.update(element, 'id', ipv4RangeToCidrv4);

internalizeRef.method.ipv4 = element => immutable.update(element, 'id', intToIpv4);
