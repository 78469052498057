import React from 'react';
import { isEqual } from 'lodash';
import IdleTimer from 'react-idle-timer';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import { apiRequest } from '../../../lib/http';
import { API } from '../../../environment/api';
import withSessionToken from '../../common/withSessionToken';
import {
  DEFAULT_IDLE_TIMEOUT,
  getOauth2TimeoutValue,
  calcKeepAliveTime,
} from '../../../utils/timeout.js';
import { determineTokenAndAuth } from '../../../utils/auth.js';

// Usage in scoutPRIME: <IdleTimeout/> should be placed in <AuthBoundary>
// so that it is instantiated after the user logs in and is authenticated,
// and is removed (and timers cleared) when logged out.

class IdleTimeout extends React.Component {
  // sp-web-ui appears to reinstantiated this class when rerendering the app,
  // because of this shouldComponentUpdate in src/components/app/index.js and in IdleTimeout is
  // used to avoid resetting IdleTimeout without any user activity, which would result in
  // IdleTimeoutnever timing out, because of autoRenew of tokens (specifically sessionToken), see
  // shouldComponentUpdate below. This was \verified by adding a temporary constructor() here.

  timeoutValue = DEFAULT_IDLE_TIMEOUT;
  idleTimer = null;
  serverTimerId = null;
  idleTimerIsReady = false;

  getTimeoutValue = (log = false) => {
    let idleTimeout = DEFAULT_IDLE_TIMEOUT; // (in milliseconds)

    if (this.props.isOauth2Configured) {
      // first set to custom Okta user profile timeout (or default), in MILLISECONDS
      idleTimeout = getOauth2TimeoutValue(this.props.sessionToken);
      log &&
        console.log(
          'Session timeout is set to ' + idleTimeout / 1000 + ' secs (from user profile).'
        );
    } else if (this.props.sessionDuration) {
      // (expected in MILLISECONDS)
      idleTimeout = this.props.sessionDuration;
      log &&
        console.log('Session timeout is set to ' + idleTimeout / 1000 + ' secs (from scoutPRIME).');
    }

    // (for dev/test, return the overriding env value if existing)
    if (!isNaN(process.env.REACT_APP_IDLE_TIMEOUT)) {
      idleTimeout = parseInt(process.env.REACT_APP_IDLE_TIMEOUT);
      log &&
        console.log(
          'Session timeout is set to ' + idleTimeout / 1000 + ' secs (from environment).'
        );
    }

    //this.setState({ timeoutValue: idleTimeout });
    //this.timeoutValue = idleTimeout;
    return idleTimeout;
  };

  // (used for debug -- must also uncomment <IdleTimer>'s onAction attribute )
  // onAction = () => {
  //   // Only used if process.env.REACT_APP_IDLE_TIMEOUT is set in .env for dev/test
  //   if (process.env.REACT_APP_IDLE_TIMEOUT) {
  //     let timeoutSecs = this.state.timeoutValue / 1000;
  //     let remainingSecs = this.idleTimer.getRemainingTime() / 1000;
  //     console.log("DEBUG (onAction):" + remainingSecs.toFixed(2) +
  //                 " remaining seconds out of " + timeoutSecs);
  //   }
  // }

  // For some reason, this event handler needs to be used. It seems to help
  // onIdle to trigger properly for multiple tabs/windows.
  onActive = () => {
    console.log('Idle time is active.');
  };

  onIdle = () => {
    // Note, this.props.isAuthenticated does not need to checked because IdleTimeout is
    // not instantiated until after the user logs in and authentication has occurred.

    // force logout and clear idle timer (which will be reestablished upon login)
    //let timeoutSecs = this.state.timeoutValue / 1000;
    let timeoutSecs = this.timeoutValue / 1000;
    console.log(
      'Session has timed out due to inactivity for ' +
        timeoutSecs +
        ' secs. Logging out of scoutPRIME.'
    );
    this.props.history.push('/logout');
    this.idleTimer = null;
  };

  // request to keep server session alive
  // (needs to be an API which requires authentication, using get current user)
  keepServerAlive = () => {
    let { auth, sessionToken, authType, userId } = this.props;

    let curSessionToken = sessionToken; // get legacy sessionToken (or oauth2 sessionToken, which may not used)
    if (authType && authType === 'oauth2') {
      // Due to shouldComponentUpdate() (below) stopping some rendering (which would have possibly have
      // refreshed sessionToken, the sessionToken may get stale when oauth2 is enabled. So, it needs to
      // be retrieved just before it is use to ensure it is not stale.
      // (determineTokenAndAuth is also used in withSessionToken and tokenThunk.)
      const oauth2TokenAndAuth = determineTokenAndAuth(auth);
      if (oauth2TokenAndAuth && oauth2TokenAndAuth.sessionToken) {
        // ensure there is a valid value
        curSessionToken = oauth2TokenAndAuth.sessionToken;
      }
    }

    if (userId) {
      let params = { 'user-id': userId };
      apiRequest(curSessionToken, API.USERS_READ, params);
    }
    console.log('User still active, keeping server alive!');
  };

  componentDidMount() {
    this.timeoutValue = this.getTimeoutValue(true); // log timeout value
    let keepAliveTime = calcKeepAliveTime(this.timeoutValue);
    console.log('Keep server alive time is ' + keepAliveTime / 1000 + ' secs.');
    this.serverTimerId = setInterval(this.keepServerAlive, keepAliveTime);
  }

  componentWillUnmount() {
    clearInterval(this.serverTimerId);
    console.log('User idle timer and server keep alive timer have been cleared.');
  }

  componentDidUpdate() {
    this.timeoutValue = this.getTimeoutValue();
    if (!this.idleTimerIsReady && this.idleTimer) {
      // Calling reset() here is a work-around when using react-idle-timer in SP,
      // where after logging in and if there are initially NO mouse or keyboard events
      // (user move the mouse off of SP browser page or does not move the mouse).
      // In this case WITHOUT using reset() here, it appears that the idle timer is running,
      // but that the onIdle event handler is not triggered, until a mouse/keyboard event
      // occurs. Buy adding reset() here the onIdle event handler is triggered with or without
      // a mouse/keyboard event. (Occurs for react-idle-timer 4.6.4 version.)
      // initTimerWithRest is used below to ensure that the above work-around using reset()
      // only occurs once, to avoid the time being reset on a subsequent update which would
      // restart the timer without user activity.
      this.idleTimer.reset();
      this.idleTimerIsReady = true;
      console.log('Idle timer IS ready.');
    } else if (!this.idleTimerIsReady) {
      console.log('Idle timer NOT ready.');
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.sessionToken &&
      this.props.sessionToken.type === 'oauth2' &&
      !isEqual(this.props.sessionToken, nextProps.sessionToken)
    ) {
      // Don't render if there was a change to sessionToken, new token values are retrieved as needed.
      // This will prevent auto renewed tokens from cause a refresh which would restart the idle timer
      // while the user is still idle, and could interupt a user doing work as well.
      return false;
    } else {
      return true;
    }
  }

  render() {
    return (
      <div>
        <IdleTimer
          ref={ref => {
            this.idleTimer = ref;
          }}
          /* element={document}          /* default document */
          /* stopOnIdle={false}          /* default false */
          /* startManually={false}       /* default false */
          /* passive={true}              /* default true */
          /* debounce={250}              /* default 0 */
          /* throttle={0}                /* default 0 */
          /* eventsThrottle={200}        /* default 200 */
          crossTab={{
            type: 'localStorage', // default undefined (tries broadcastMessage then localStorage)
            // channelName: 'idle-timer', // default idle-timer
            // fallbackInterval: 2000,    // default 2000
            responseTime: 200, // How long tab instances will have to respond. Default 100
            // removeTimeout: 1000 * 60,  // LocalStorage item time to live. Default 1000 * 60
            emitOnAllTabs: true, // Emit events on all tabs/windows of a browser. Default false
          }}
          timeout={this.timeoutValue}
          /* onAction={this.onAction}   /* (used for debug) */
          onActive={this.onActive}
          onIdle={this.onIdle}></IdleTimer>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, user }) => ({
  isOauth2Configured: get(auth, 'config.data.oauth2'),
  sessionDuration: user.sessionDuration,
  userId: user.userId,
  auth,
});

export default withRouter(withSessionToken(connect(mapStateToProps)(IdleTimeout)));
