import theme from '../../../../theme';

export const modalStyles = {
  dialog: {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      zIndex: theme.zIndex.modal,
    },
    content: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      background: '#FFFFFF',
      boxShadow: theme.boxShadows.medium,
      borderRadius: theme.borderRadius.default,
      padding: 0,
      border: '1px solid transparent',
    },
  },
};

export default theme => ({
  container: ({ width }) => ({
    width,
    minHeight: 200,
    position: 'relative',
    maxHeight: '98vh', // Do not allow the modal height to exceed the viewport height
    backgroundColor: theme.colors.pearlWhite,
  }),
  content__container: {
    padding: '15px 40px 30px 40px',
    minWidth: 350,
  },
  closeIcon: {
    position: 'absolute',
    top: 15,
    right: 5,
    color: '#777',
    padding: 10,
  },
  noDecorator: {
    display: 'none',
  },
  noPadding: {
    padding: '0 !important',
  },
});
