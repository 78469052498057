import React from 'react';
import injectStyles from 'react-jss';
import AsyncCreatable from 'react-select/async-creatable';
import selectStyles from '../../../selects/styles';
import ClearIndicator from '../../../selects/ClearIndicator';
import FieldError from '../../errors/FieldError';
import cx from 'classnames';
import styles from '../../styles';

export default injectStyles(styles)(
  ({
    cacheOptions,
    className,
    classes,
    closeMenuOnSelect = true,
    input: field,
    isSearchable = true,
    loadOptions,
    multi = false,
    meta,
    menuPortalTarget = document.body,
    disabled,
    placeholder = 'Select...',
  }) => (
    <div className={classes.field}>
      <AsyncCreatable
        cacheOptions={cacheOptions}
        className={cx(classes.select, className)}
        closeMenuOnSelect={closeMenuOnSelect}
        components={{
          ClearIndicator,
        }}
        defaultOptions={true}
        isClearable={true}
        isDisabled={disabled}
        isMulti={multi}
        isSearchable={isSearchable}
        loadOptions={loadOptions}
        menuPortalTarget={menuPortalTarget}
        onChange={field.onChange}
        placeholder={placeholder}
        styles={selectStyles({ multi })}
        value={field.value}
      />
      <FieldError {...meta} />
    </div>
  )
);
