import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  fetchEntities,
  fetchEntitiesSilently,
  refreshWorkspaceCollections,
} from '../../../redux/modules/entities/actions';
import { API } from '../../../environment/api';
import { connect } from 'react-redux';
import qs from 'query-string';
import size from 'lodash/size';
import decode from 'safe-decode-uri-component';
import find from 'lodash/find';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import { switchWorkspace } from '../../../utils/routing';
import { injectIntl } from 'react-intl';
import { setWorkspace } from '../../../redux/modules/app/actions';
import notification from '../notification';
import { fetchPermissions } from '../../../redux/modules/auth/actions';
import { baseWorkspacePermissions } from '../../../utils/permissions';

/**
 * Manages choosing a workspace and setting its id in the app state. Facilitates the
 * usage of withWorkspace.
 */
class Index extends React.Component {
  /**
   * Do the actions necessary to switch to a workspace.
   *
   * @param workspaceName
   */
  selectWorkspace = workspaceName => {
    switchWorkspace(this.props.history, workspaceName);
  };

  /**
   * Select the first workspace from the list of available workspaces.
   */
  selectFirstWorkspace = () => {
    const { workspaces } = this.props;
    const firstWorkspaceName = get(
      orderBy(workspaces, workspace => workspace.name && workspace.name.toLowerCase(), 'asc'),
      [0, 'name']
    );
    this.selectWorkspace(firstWorkspaceName);
  };

  /**
   * Return the currently available workspace name. This is pulled from a URL param.
   */
  currentWorkspaceName = (props = this.props) => {
    const { location } = props;
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });
    const encodedWorkspaceName = params.workspace;
    if (encodedWorkspaceName) {
      return decode(encodedWorkspaceName);
    }
  };

  /**
   * Select a workspace based on the user and workspace state. If the user has a default
   * workspace, it is chosen. If they don't, the first workspace in the workspace list is
   * chosen.
   */
  autoSelectWorkspace = () => {
    const { defaultWorkspaceId, workspaces } = this.props;
    if (size(workspaces) === 0) {
      return;
    }
    const defaultWorkspace = find(workspaces, { 'workspace-id': defaultWorkspaceId });
    if (defaultWorkspace) {
      this.selectWorkspace(defaultWorkspace.name);
    } else {
      this.selectFirstWorkspace();
    }
  };

  /***
   * Finds the workspace by ID
   * @params string workspaceId -- the id of the workspace we want to find
   */
  selectWorkspaceById = workspaceId => {
    let { workspaces } = this.props;
    let defaultWorkspace = find(workspaces, { 'workspace-id': workspaceId });
    if (defaultWorkspace) {
      this.selectWorkspace(defaultWorkspace.name);
    } else {
      this.selectFirstWorkspace();
    }
  };

  /**
   * When the user is attempting to user a workspace they don't have access to, notify
   * them, and direct them to a workspace they do have access to.
   *
   * @param workspaceName
   */
  handleUnknownWorkspace = workspaceName => {
    const message = this.props.intl.formatMessage(
      {
        id: 'workspace-not-found',
        defaultMessage: 'You do not have access to {workspaceName}. Workspace has been changed.',
      },
      { workspaceName }
    );
    notification.error(message);
    this.autoSelectWorkspace();
  };

  /**
   * Set (via dispatching to the app state) the current workspace. If no workspace is
   * available, start figuring out a way to make it available.
   */
  setWorkspace = () => {
    const {
      fetchPermissions,
      organizationId,
      refreshWorkspaceCollections,
      setWorkspace,
      userId,
      workspaces,
      workspaceId,
    } = this.props;
    if (size(workspaces) === 0) {
      return;
    }
    const workspaceName = this.currentWorkspaceName();
    if (!workspaceName) {
      // the user had no workspace in their url
      // Then first, check if we have the workspace in the state
      if (workspaceId) {
        this.selectWorkspaceById(workspaceId);
      } else {
        this.autoSelectWorkspace();
      }
      return;
    }
    const workspace = find(workspaces, { name: workspaceName });

    if (!workspace) {
      // the user had a workspace in their url, but that workspace is not one that's available to them
      this.handleUnknownWorkspace(workspaceName);
      return;
    }

    const realWorkspaceId = workspace['workspace-id'];
    refreshWorkspaceCollections(realWorkspaceId, { clear: true });
    fetchPermissions(userId, baseWorkspacePermissions(userId, organizationId, realWorkspaceId));
    setWorkspace(realWorkspaceId);
  };

  componentDidMount() {
    const { fetchOrgWorkspaces, organizationId } = this.props;
    // Initialize the organization data that we need to load the app e.g, workspace and users
    fetchOrgWorkspaces(organizationId);
    this.setWorkspace();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { workspaces, workspaceId } = this.props;
    if (
      // either the workspace potentially changed...
      this.currentWorkspaceName(prevProps) !== this.currentWorkspaceName() ||
      // or the workspace was never set in the first place, but can now be set
      (!workspaceId && size(workspaces) > 0)
    ) {
      this.setWorkspace();
    }
  }

  render() {
    // this component is just managing the lifecycle, it doesn't provide another component
    return <React.Fragment>{this.props.children}</React.Fragment>;
  }
}

const mapStateToProps = ({
  app: { workspaceId },
  entities: {
    workspaces: { loading: isLoadingWorkspaces, data: workspaces },
  },
  user: { defaultWorkspaceId, organizationId, userId },
}) => ({
  defaultWorkspaceId,
  isLoadingWorkspaces,
  organizationId,
  userId,
  workspaces,
  workspaceId,
});

const mapDispatchToProps = {
  fetchOrgWorkspaces: (organizationId, silently = false) => {
    const fetchFn = silently ? fetchEntitiesSilently : fetchEntities;
    return fetchFn('workspaces', API.WORKSPACES_READ_ALL, {
      'organization-id': organizationId,
    });
  },
  refreshWorkspaceCollections,
  fetchPermissions,
  setWorkspace,
};

export default withRouter(
  injectIntl(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(Index)
  )
);
