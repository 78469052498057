import assign from 'lodash/fp/assign';
import { isEmpty } from 'lodash';

export function actionError(actionType) {
  return `${actionType}_ERROR`;
}

export function actionStart(actionType) {
  return `${actionType}_START`;
}

export function actionSuccess(actionType) {
  return `${actionType}_SUCCESS`;
}

function fetchStarted(action, state) {
  return assign(state, {
    error: null,
    isLoading: !action.merge || !isEmpty(state.data),
    isRequesting: true,
  });
}

function fetchErrored(action, state) {
  return assign(state, {
    error: action.payload,
    isLoading: false,
    isRequesting: false,
  });
}

const initialState = {
  data: null,
  error: null,
  isLoading: false,
  isRequesting: false,
};

export const apiData = {
  fetchErrored,
  fetchStarted,
  initialState,
};
