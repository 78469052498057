import React from 'react';
import { connect } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Loadable from 'react-loadable';
import { addLocaleData, injectIntl, IntlProvider } from 'react-intl';
import en from 'react-intl/locale-data/en';
import get from 'lodash/get';
import Matomo from '../../lib/Matomo';
import { AsyncLoader, LoadingIndicator, RouteWithTitle } from '../base';
import App from '.';
import Okta from '../common/Okta';
import { fetchAuthConfig } from '../../redux/modules/auth/actions';
import { LastLocationProvider } from 'react-router-last-location';
import withSessionToken from '../common/withSessionToken';
import { Toast } from '../common/Toast';

const ApiDocs = Loadable({
  loader: () => import('./Docs/Api'),
  loading: AsyncLoader,
});

const BootstrapUser = Loadable({
  loader: () => import('./Auth/Bootstrap'),
  loading: AsyncLoader,
});

const ConvertToken = Loadable({
  loader: () => import('./Auth/Convert'),
  loading: AsyncLoader,
});

const ForgotPassword = Loadable({
  loader: () => import('./ForgotPassword'),
  loading: AsyncLoader,
});

const Login = Loadable({
  loader: () => import('./Login'),
  loading: AsyncLoader,
});

const Logout = Loadable({
  loader: () => import('./Logout'),
  loading: AsyncLoader,
});

const SetPassword = Loadable({
  loader: () => import('./SetPassword'),
  loading: AsyncLoader,
});

const MainRouter = injectIntl(({ intl }) => (
  <Switch>
    <RouteWithTitle
      path="/docs/api"
      component={ApiDocs}
      title={intl.formatMessage({
        id: 'route-title-api-docs',
        defaultMessage: 'API Documentation',
      })}
    />
    <RouteWithTitle
      exact
      path="/auth/bootstrap"
      title={intl.formatMessage({
        id: 'route-title-auth-bootstrap',
        defaultMessage: 'Bootstrapping User',
      })}
      component={BootstrapUser}
    />
    <RouteWithTitle
      exact
      path="/auth/convert"
      title={intl.formatMessage({
        id: 'route-title-auth-convert',
        defaultMessage: 'Resuming Session',
      })}
      component={ConvertToken}
    />
    <RouteWithTitle
      exact
      path="/forgot-password"
      title={intl.formatMessage({
        id: 'route-title-forgot-password',
        defaultMessage: 'Forgot Password',
      })}
      component={ForgotPassword}
    />
    <RouteWithTitle
      exact
      path="/set-password/token/:token"
      title={intl.formatMessage({
        id: 'route-title-set-password',
        defaultMessage: 'Set Your Password',
      })}
      component={SetPassword}
    />
    <RouteWithTitle
      exact
      path="/login"
      title={intl.formatMessage({
        id: 'route-title-login',
        defaultMessage: 'Login',
      })}
      component={Login}
    />
    <RouteWithTitle
      exact
      path="/logout"
      title={intl.formatMessage({
        id: 'route-title-logout',
        defaultMessage: 'Logout',
      })}
      component={Logout}
    />
    <Route
      path="/login/callback"
      render={() => <LoadingIndicator fadeIn="quarter" showing={true} full />}
    />
    <Route path="/" component={App} />
  </Switch>
));

class LocalizedRoot extends React.Component {
  state = {
    history: createBrowserHistory(),
  };

  initializeMatomo = () => {
    const { matomo, matomoUserTrackingId } = this.props;
    const matomoHook = new Matomo({
      ...matomo,
      userId: matomoUserTrackingId,
    }).initialize();

    this.setState(({ history }) => ({
      history: matomoHook.connectToHistory(history),
    }));
  };

  componentDidUpdate({ matomo }) {
    // Initialize matomo and integrate with router history
    if (
      !matomo &&
      this.props.matomo &&
      this.props.matomo.url &&
      (this.props.matomo.siteId ||
        get(matomo, 'url') !== this.props.matomo.url ||
        get(matomo, 'siteId') !== this.props.matomo.siteId)
    ) {
      this.initializeMatomo();
    }
  }

  componentDidMount() {
    // Add locales
    addLocaleData([...en]);
    this.props.fetchAuthConfig();
    if (
      this.props.isAuthenticated &&
      this.props.matomo &&
      typeof this.props.matomo.url !== 'undefined' &&
      typeof this.props.matomo.siteId !== 'undefined'
    ) {
      this.initializeMatomo();
    }
  }

  render() {
    const { locale, messages } = this.props;
    return (
      <IntlProvider key={locale} locale={locale} messages={messages}>
        <Toast>
          <Router history={this.state.history}>
            <LastLocationProvider>
              <React.Fragment>
                <Okta />
                <MainRouter />
              </React.Fragment>
            </LastLocationProvider>
          </Router>
        </Toast>
      </IntlProvider>
    );
  }
}

const mapStateToProps = ({
  auth: {
    apiTokens: { matomo },
  },
  user: { username, userId },
  locale,
}) => ({
  ...locale,
  matomo,
  matomoUserTrackingId: `${username}:${userId}`,
});

const mapDispatchToProps = {
  fetchAuthConfig,
};

export default withSessionToken(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LocalizedRoot)
);
