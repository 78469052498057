import React from 'react';
import { connect } from 'react-redux';

import packageJson from '../../../package.json';
import withSessionToken from '../common/withSessionToken';
import { clearSearchOptions } from '../../redux/modules/workspace/search/actions';

global.appVersion = packageJson.version;

// The reason we are not using service workers to keep app up-to date is because of IE 11.
// In order to achieve consistent behaviour across the browsers we are going with this solution.
// https://dev.to/flexdinesh/cache-busting-a-react-app-22lk

// version from response - first param, local version second param
const doWeHaveNewVersion = (versionA, versionB) => {
  if (versionA !== versionB) return true;
  return false;
};

class CacheBuster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isLatestVersion: false,
      refreshCacheAndReload: () => {
        console.log('Clearing cache and hard reloading...');
        if (window.Cache !== undefined) {
          // Service worker cache should be cleared with caches.delete()
          caches.keys().then(async function(names) {
            await Promise.all(names.map(name => caches.delete(name)));
          });
          // delete browser cache and hard reload
          window.location.reload(true);
        }
      },
    };
  }

  fetchMeta = () => {
    fetch(`/meta.json?${new Date().getTime()}`, { cache: 'no-cache' })
      .then(response => response.json())
      .then(meta => {
        const latestVersion = meta.version;
        const currentVersion = global.appVersion;

        const shouldForceRefresh = doWeHaveNewVersion(latestVersion, currentVersion);
        if (shouldForceRefresh) {
          this.props.clearSearchOptions();
          console.log(`We have a new version - ${latestVersion}. Should force refresh`);
          this.setState({ loading: false, isLatestVersion: false });
        } else {
          console.log(
            `You already have the latest version - ${latestVersion}. No cache refresh needed.`
          );
          this.setState({ loading: false, isLatestVersion: true });
        }
      });
  };

  componentDidMount() {
    this.fetchMeta();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isAuthenticated !== this.props.isAuthenticated) {
      this.fetchMeta();
    }
  }

  render() {
    const { loading, isLatestVersion, refreshCacheAndReload } = this.state;
    return this.props.children({ loading, isLatestVersion, refreshCacheAndReload });
  }
}

const mapDispatchToProps = {
  clearSearchOptions,
};

export default withSessionToken(
  connect(
    null,
    mapDispatchToProps
  )(CacheBuster)
);
