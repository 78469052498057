import identity from 'lodash/identity';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import merge from 'lodash/merge';
import take from 'lodash/take';
import update from 'lodash/update';
import has from 'lodash/has';
import { apiRequest, apiRequestRaw } from '../../../lib/http';
import { API } from '../../../environment/api';
import { camelCaseKeys, tokenThunk } from '../../../utils/api';
import { toSelectOptions } from '../../../utils/forms';

const normalizeOrg = org => {
  const normalized = camelCaseKeys(org);
  return merge(normalized, { 'organization-id': normalized.organizationId });
};

const organizationNormalizer = ({ result }) => {
  return normalizeOrg(result);
};

/**
 * This takes the organization tree and flattens them into a flat
 * list of organizations. Instead of an orgs children being inside their
 * `children` field, those child orgs are moved up to the top level, and the
 * `children` field is just a list of organization-ids
 * @param result
 * @returns {*}
 */
const organizationReducer = ({ result }) => {
  const flattened = result.children.reduce(
    (accumulator, org) => {
      return [...accumulator, org, ...org.children];
    },
    [result]
  );
  const childrenRefed = flattened.map(org => {
    return update(org, 'children', children => {
      return children.map(child => get(child, 'organization-id'));
    });
  });
  return childrenRefed.map(normalizeOrg);
};

// For Collections that don't have a TIC score yet, assign the default score.
// expects an array of objects.
function addDefaultScore(collectionArray = []) {
  return collectionArray.map(collection => {
    if (!has(collection, 'tic-score')) {
      collection['tic-score'] = 10;
    }
    return collection;
  });
}

// Functions that normalize fetches for certain url requests since the api is not very consistent with return objects
const entityNormalizers = {
  // TODO: Figure out why saved search and search history dont actually have limit and sorting
  [API.SAVED_SEARCH_READ_ALL]: ({ result }) => orderBy(result, 'created-at', 'desc'),
  [API.SEARCH_HISTORY_CREATE]: (entity, { result }) => ({
    ...entity,
    ...result,
  }),
  [API.SAVED_SEARCH_CREATE]: (entity, { result }) => ({
    ...entity,
    ...result,
  }),
  [API.COLLECTIONS_READ_ALL]: ({ result }) => addDefaultScore(result),
  [API.SEARCH_HISTORY_READ_ALL]: ({ result }) => take(orderBy(result, 'created-at', 'desc'), 100),
  [API.USERS_READ_ALL]: ({ result }) => result.users,
  [API.WORKSPACES_READ_ALL]: ({ result }) => result.workspaces,
  [API.ORGANIZATION_READ_ALL]: organizationReducer,
};

const entityFetchByIdNormalizers = {
  [API.ORGANIZATIONS_GET]: organizationNormalizer,
  [API.REPORTS_STATUS]: ({ result }) => camelCaseKeys(result),
};

// Aliases just in case we change the underlying request verbs
// Our API currently makes everything a POST but that may not be the case in the future iterations
export const fetchEntitiesSelectOptions = (
  token,
  url,
  params,
  valueKey,
  labelKey,
  opts = {},
  id = null
) =>
  apiRequest(token, url, params).then(({ result }) =>
    toSelectOptions(
      opts && opts.path ? get(result, opts.path) : result,
      valueKey,
      labelKey,
      opts,
      id
    )
  );

export const fetchEntitiesRaw = (token, url, params, ...moreParams) =>
  apiRequest(token, url, params, ...moreParams);

export const fetchEntitiesByIdRaw = (token, url, params, ...moreParams) =>
  apiRequest(token, url, params, ...moreParams);

export const createEntityRaw = (token, url, params, ...moreParams) =>
  apiRequest(token, url, params, ...moreParams);

export const editEntityRaw = (token, url, params, ...moreParams) =>
  apiRequest(token, url, params, ...moreParams);

export const deleteEntityRaw = (token, url, params, ...moreParams) =>
  apiRequest(token, url, params, ...moreParams);

// Redux Actions and Creators

const actions = {
  FETCH: 'entities/FETCH',
  FETCH_BY_ID: 'entities/FETCH_BY_ID',
  CREATE: 'entities/CREATE',
  DELETE: 'entities/DELETE',
  UPSERT: 'entities/UPSERT',
  UPDATE: 'entities/UPDATE',
};

const extractResult = ({ result }) => result;

/**
 * An unfortunate difference from the main fetchEntities function below. That function
 * assumes certain standards around how the API acts that used to be true. The new
 * lg-api-2 standards are more RESTish and thus need more exposed. This will eventually
 * become the standard.
 *
 * @param entityType
 * @param method
 * @param path
 * @returns {Function}
 */
export function fetchEntitiesApi2(entityType, method, path) {
  return tokenThunk((dispatch, token) => {
    dispatch({
      entityType,
      type: actions.FETCH,
      payload: apiRequestRaw(method, path, token),
    });
  });
}

/**
 * Returns a thunk that fetches entities by `entityType`, `url`, and other `params`.
 *
 * Options are:
 *   * clear - Whether to remove all existing entities before the fetch or not.
 *   * merge - Whether to merge individual entities (true) or replace them entirely (false).
 *   * silently - Whether to set the `loading` flag or not while fetching.
 *
 * @param entityType
 * @param url
 * @param params
 * @param opts
 * @returns {Function}
 */
export function fetchEntities(entityType, url, params, opts = {}) {
  opts = { clear: true, merge: false, silently: false, ...opts };
  return tokenThunk((dispatch, token) => {
    const resultHandler = entityNormalizers[url] || extractResult;
    dispatch({
      entityType,
      opts,
      params,
      type: actions.FETCH,
      payload: fetchEntitiesRaw(token, url, params).then(resultHandler),
    });
  });
}

/**
 * Returns a customized `fetchEntities` call that adds a `silently` option as to not trigger a loading indicator.
 * This is a very special usecase of fetching where we want it to happen but do it "behind the scenes". The option
 * is handled within the reducer
 * @param entityType
 * @param url
 * @param params
 * @returns {Function}
 */
export const fetchEntitiesSilently = (entityType, url, params) =>
  fetchEntities(entityType, url, params, {
    silently: true,
  });

/**
 * Returns a thunk that fetches a single entity by `entityType`, `url`, and other `params`
 * @param entityType
 * @param url
 * @param params
 * @returns {Function}
 */
export const fetchEntityById = (entityType, url, params) =>
  tokenThunk((dispatch, token) => {
    const resultHandler = entityFetchByIdNormalizers[url] || extractResult;

    dispatch({
      entityType,
      params,
      type: actions.FETCH_BY_ID,
      payload: fetchEntitiesByIdRaw(token, url, params).then(resultHandler),
    });
  });

/**
 * Returns a thunk that creates a single entity by `entityType`, `url`, and other `params`
 * @param entityType
 * @param url
 * @param params
 * @returns {Function}
 */
export const createEntity = (entityType, url, params) =>
  tokenThunk((dispatch, token) => {
    const resultHandler = entityNormalizers[url] || identity;
    dispatch({
      entityType,
      params,
      type: actions.CREATE,
      payload: createEntityRaw(token, url, params).then(resultHandler.bind(this, params)),
    });
  });

/**
 * Returns a thunk that deletes a single entity by `entityType`, `url`, and other `params`
 * @param entityType
 * @param url
 * @param params
 * @returns {Function}
 */
export const deleteEntity = (entityType, url, params) =>
  tokenThunk((dispatch, token) => {
    dispatch({
      entityType,
      params,
      type: actions.DELETE,
      payload: deleteEntityRaw(token, url, params),
    });
  });

/**
 * Returns an action creator that updates an entity by `entityType`, `entityId`, and the `entity` map
 * @param entityType
 * @param entityId
 * @param entity
 * @returns {{meta: {action: {entityType: *, entityId: *}}, type: string, payload: {entity: *}}}
 */
export const updateEntity = (entityType, entityId, entity) => ({
  meta: { action: { entityType, entityId } },
  type: actions.UPDATE,
  payload: {
    entity,
  },
});

/**
 * Returns an action creator that upserts an entity by `entityType` and `entity` map
 * @param entityType
 * @param entity
 * @returns {{meta: {action: {entityType: *}}, type: string, payload: {entity: *}}}
 */
export const upsertEntity = (entityType, entity) => ({
  meta: { action: { entityType } },
  type: actions.UPSERT,
  payload: {
    entity,
  },
});

const refreshDefaultOpts = {
  clear: false,
  merge: true,
  silently: false,
};

export function refreshOrgWorkspaces(organizationId, opts = {}) {
  opts = { ...refreshDefaultOpts, ...opts };
  const params = {
    'organization-id': organizationId,
  };
  return fetchEntities('workspaces', API.WORKSPACES_READ_ALL, params, opts);
}

export function refreshOrgUsers(organizationId, opts = {}) {
  opts = { ...refreshDefaultOpts, ...opts };
  const params = {
    'organization-id': organizationId,
  };
  return fetchEntities('users', API.USERS_READ_ALL, params, opts);
}

/**
 * Fetches and replaces the workspace collections from the redux store. (entities->collections)
 * Use this function to get latest collections by workspace
 * @param workspaceId
 * @param opts
 */
export function refreshWorkspaceCollections(workspaceId, opts = {}) {
  opts = { ...refreshDefaultOpts, ...opts };
  const params = {
    'workspace-id': workspaceId,
    attributes: [
      'assigned-to',
      'collection-id',
      'children',
      'description',
      'name',
      'parent-id',
      'source',
      'stats',
      'tic-score',
      'workspace-id',
    ],
  };
  return fetchEntities('collections', API.COLLECTIONS_READ_ALL, params, opts);
}

export function fetchTokens(userId) {
  return fetchEntitiesApi2('tokens', 'GET', `/internal/users/${encodeURIComponent(userId)}/tokens`);
}

export default actions;
