import qs from 'query-string';
import pick from 'lodash/pick';
import { replaceParams } from './history';
import { setParams } from './url';
import { isDev } from './env';

/**
 * This is legacy behavior from lg-navigator. Since the URLs are synched, until
 * lg-navigator goes away, it will be maintained. Ideally we shouldn't be treating
 * the slash in a CIDR as part of the path, since it's part of the id of the CIDR.
 * @param elementType
 * @param elementId
 * @returns {*}
 */
function encodeElementId(elementType, elementId) {
  switch (elementType) {
    case 'cidr':
    case 'cidrv4':
    case 'cidrv6':
    case 'threat':
    case 'owner': {
      return encodeURIComponent(elementId);
    }
    default:
      return elementId;
  }
}

/*
 * Returns to the previous page if the app has any previous states,
 * if not defaults to workspace summary page.
 */

export const goBack = (location, history) => {
  // location.key is more reliable than history.length
  // to check if the page loaded is for the first time
  if (location.key) {
    return history.goBack();
  }
  return history.push(workspaceUrl(location));
};

/**
 * Creates a URL to path, taking into account the current location. This will maintain
 * common context like workspace.
 *
 * Right now this assumes that the path does not include its own params. If this changes,
 * this will need to be fancier.
 * @param location The router location object.
 * @param path The path to link to.
 * @param paramsToKeep Params to keep
 * @returns {string}
 */
export const keepContext = (location, path, paramsToKeep = ['workspace']) => {
  const currentParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const newParams = pick(currentParams, paramsToKeep);
  return setParams(path, newParams);
};

export const dialogPath = (location, dialogSubpath) => {
  let slash = '/';
  if (location.pathname.endsWith(slash)) {
    slash = '';
  }
  return `${location.pathname}${slash}dialog${dialogSubpath}`;
};

/**
 * If the location is a dialog location (ends with /dialog/*), removes the dialog part of
 * the path and returns that path. If the path did not point at a path the path is
 * returned unmodified.
 *
 * @param location
 * @returns {string}
 */
export const dialoglessPath = location => {
  const matches = location.pathname.match(/^(.+)\/dialog\/.+$/);
  if (!matches) {
    return location.pathname;
  }
  return matches[1];
};

export const dialogUrl = (location, dialogSubpath) => {
  return keepContext(location, dialogPath(location, dialogSubpath));
};

/**
 * Returns a map that returns the simplified key for the legacy element details page
 * TODO: Remove when we port to the new element details page. Just use ipv4 and cidrv4
 * @type {{ipv4: string, cidrv4: string}}
 */
export const elementDetailsRouteType = {
  ipv4: 'ip',
  cidrv4: 'cidr',
};

export const switchOrganization = (history, organizationName) => {
  replaceParams(history, { organization: organizationName });
};

export const updateOrganizationDialog = location => {
  return keepContext(location, dialogPath(location, '/update-organization'), [
    'workspace',
    'organization',
  ]);
};

export const switchWorkspace = (history, workspaceName) => {
  replaceParams(history, { workspace: workspaceName });
};

/**
 * Adds a dev=true param to the URL if the environment says it's a dev environment. This
 * is currently only used for communication with the cljs app, so presumably when that
 * goes away, this can also go away.
 *
 * @param url
 * @returns {*}
 */
export const setDevParam = url => {
  if (isDev()) {
    return setParams(url, { dev: true });
  }
  return url;
};

// Paths
// =====
// Partial URLs. Note that if these are used the search context (i.e. workspace-id) needs
// to still be passed along.

export const addIncidentPath = location => {
  return dialogPath(location, '/incidents/add');
};

export const addIncidentFolderUrl = location => {
  return dialogUrl(location, '/add-incident-folder');
};

/**
 * Create a path to a dialog to delete a note. This only works when created as a dialog on
 * top of a page that has notes on it, as that provides context during the handling of
 * the route.
 *
 * @param location
 * @param noteId
 * @returns {string}
 */
export const deleteNotePath = (location, noteId) => {
  return dialogPath(location, `/note/delete/${encodeURIComponent(noteId)}`);
};

/**
 * Create a path to a dialog to edit a note. This only works when created as a dialog on
 * top of a page that has notes on it, as that provides context during the handling of
 * the route.
 *
 * @param location
 * @param noteId
 * @returns {string}
 */
export const editNotePath = (location, noteId) => {
  return dialogPath(location, `/note/edit/${encodeURIComponent(noteId)}`);
};

export const includeInCollectionPath = location => {
  return dialogPath(location, '/collections/include');
};

// URLs
// ====
// Builds URLs for use anywhere in the app.

export const aboutUrl = location => {
  return dialogUrl(location, '/about');
};

export const adminGroupsUrl = location => {
  return adminUrl(location, 'groups');
};

export const adminUrl = (location, page) => {
  return keepContext(location, `/admin/${page}`, ['workspace', 'organization']);
};

export const adminUsersUrl = location => {
  return adminUrl(location, 'users');
};

export const adminWorkspacesUrl = location => {
  return adminUrl(location, 'workspaces');
};

/**
 * Create a URL to a dialog to add a note. This only works when created as a dialog on
 * top of a page that has notes on it, as that provides context during the handling of
 * the route.
 *
 * This will only work on a location that
 *
 * @param location
 * @returns {string}
 */
export const addNoteUrl = location => {
  return dialogUrl(location, `/note/add`);
};

export const changePasswordUrl = location => {
  return dialogUrl(location, '/change-password');
};

export const childAssociationsUrl = (
  location,
  elementType,
  elementId,
  riskId,
  riskType = 'threat'
) => {
  const type = ensureElementType(elementType);
  elementId = encodeElementId(type, elementId);
  riskId = encodeURIComponent(riskId);
  return keepContext(location, `/elements/${type}/${elementId}/${riskType}/${riskId}/associations`);
};

/**
 * Create a path to a collection, based on its id. `workspaceName` is the name of the
 * workspace that the collection is in to facilitate cross-workspace linking. If
 * `workspaceName` it is assumed that the collection is in the current workspace and that
 * param is preserved.
 *
 * @param location
 * @param collectionId
 * @param workspaceName
 * @returns {string}
 */
export function collectionUrl(location, collectionId, workspaceName) {
  const path = `/collection-management/${encodeURIComponent(collectionId)}`;
  if (workspaceName) {
    return `${path}?workspace=${encodeURIComponent(workspaceName)}`;
  }
  return keepContext(location, path);
}

export function collectionsUrl(location) {
  return keepContext(location, '/collection-management');
}

export function collectionViewAllClassificationsUrl(location, collectionId, params = {}) {
  collectionId = encodeURIComponent(collectionId);
  let path = `/collection-management/${collectionId}/classifications`;
  return keepContext(location, setParams(path, params));
}

export function collectionNotificationsUrl(location, collectionId, params = {}) {
  collectionId = encodeURIComponent(collectionId);
  let path = `/collection-management/${collectionId}/notifications`;
  return keepContext(location, setParams(path, params));
}

export function collectionViewAllElementsUrl(location, collectionId, params = {}) {
  collectionId = encodeURIComponent(collectionId);
  let path = `/collection-management/${collectionId}/elements`;
  const options = [];
  if (params.severity) {
    options.push(`severity=${params.severity}`);
  }
  if (params.elementType) {
    options.push(`elementType=${params.elementType}`);
  }
  if (options.length) {
    path = `${path}?${options.join('&')}`;
  }
  return keepContext(location, path);
}

export function collectionViewAllOwnersUrl(location, collectionId, params = {}) {
  collectionId = encodeURIComponent(collectionId);
  let path = `/collection-management/${collectionId}/ownership`;
  return keepContext(location, setParams(path, params));
}

export function collectionViewAllThreatsUrl(location, collectionId, params = {}) {
  collectionId = encodeURIComponent(collectionId);
  let path = `/collection-management/${collectionId}/risks`;
  if (params.severity) {
    path = `${path}?severity=${params.severity}`;
  }
  return keepContext(location, path);
}

export const createTokenUrl = (location, userId) => {
  return keepContext(location, `/user/${encodeURIComponent(userId)}/api/token/create`);
};

export const createWorkspaceUrl = location => {
  return dialogUrl(location, '/create-workspace');
};

export const deleteTokenUrl = (location, tokenId) => {
  return dialogUrl(location, `/token/delete/${encodeURIComponent(tokenId)}`);
};

export const editTokenUrl = (location, userId, tokenId) => {
  return keepContext(
    location,
    `/user/${encodeURIComponent(userId)}/api/token/edit/${encodeURIComponent(tokenId)}`
  );
};

function ensureElementType(elementType) {
  switch (elementType) {
    case 'cidr':
    case 'cidrv4':
    case 'cidrv6':
      return 'cidr';
    case 'ip':
    case 'ipv4':
    case 'ipv6':
      return 'ip';
    default:
      return elementType;
  }
}

export const elementDetailsUrl = (location, elementType, elementId) => {
  const type = ensureElementType(elementType);
  elementId = encodeElementId(type, elementId);
  return keepContext(
    location,
    elementType === null || elementId === null ? '/elements' : `/elements/${type}/${elementId}`
  );
};

export const exportUrl = location => {
  return dialogUrl(location, '/export');
};

export function importsUrl(location) {
  return keepContext(location, '/system-metrics/imports');
}

export const includeInCollectionUrl = location => {
  return dialogUrl(location, '/collections/include');
};

export function incidentManagementFolderUrl(location, folderId) {
  return keepContext(location, `/incident-management/${encodeURIComponent(folderId)}`);
}

export function incidentManagementUrl(location) {
  return keepContext(location, '/incident-management');
}

export function threatStatsUrl(location) {
  return keepContext(location, '/system-metrics/threat-stats');
}

/**
 * Create a URL to search.
 *
 * `maintainSearch` is somewhat of a hack because the way search currently works. It
 * assumes anything in the search part of the URL is a filter, instead of passing around a
 * distinct set of filters. Until that changes, setting maintainSearch true will just
 * pass the location on as is.
 *
 * @param location
 * @param query
 * @param searchType
 * @param maintainSearch
 * @returns {string}
 */
export const searchUrl = (location, query, searchType = 'search', maintainSearch = false) => {
  // So we dont end up with a url like /search/search/...make the search type `basic`
  const urlSearchType = searchType === 'search' ? 'basic' : searchType;

  let url = `/search/${urlSearchType}/${encodeURIComponent(query)}`;
  if (maintainSearch) {
    url = `${url}${location.search}`;
  } else {
    // if we're not maintaining the full search, we still need to keep things like workspace
    url = keepContext(location, url);
  }
  return url;
};

export const ticConfigurationUrl = location => {
  return dialogUrl(location, '/tic-configuration');
};

export const addWorkspaceCollectionUrl = location => {
  return dialogUrl(location, '/add-workspace-collection');
};

export const deleteWorkspaceUrl = location => {
  return dialogUrl(location, '/delete');
};

export const editWorkspaceUrl = location => {
  return dialogUrl(location, '/edit');
};

/**
 * Returns a url string with an encoded workspace name
 * @param location
 * @param path
 * @returns {string}
 */
export const workspaceUrl = (location, path = '') => keepContext(location, `/workspace${path}`);

export const reportUrl = (location, reportId) => {
  const encodedReportId = encodeURIComponent(reportId);
  return keepContext(location, `/report/preview/${encodedReportId}`);
};

export const reportSchedulePath = scheduleId => {
  const encodedScheduleId = encodeURIComponent(scheduleId);
  return `/report/schedule/${encodedScheduleId}`;
};

export const deleteReportUrlPath = (location, scheduleId) => {
  const encodedScheduleId = encodeURIComponent(scheduleId);
  return dialogUrl(location, `/delete/${encodedScheduleId}`);
};

export const reportScheduledListUrl = location => {
  return keepContext(location, '/report/');
};

export const labelEditorUrl = location => {
  return dialogUrl(location, `/labels`);
};

export const userApiUrl = (location, userId) => {
  return keepContext(location, `/user/${encodeURIComponent(userId)}/api`);
};

export const newThreatUrl = location => {
  return keepContext(location, '/threats/new');
};

export const newThreatAssociationUrl = location => {
  return dialogUrl(location, `/new/association`);
};

export const deleteThreatAssociationUrl = location => {
  return dialogUrl(location, '/delete/association');
};

export const deleteThreatUrl = location => {
  return dialogUrl(location, `/delete`);
};

export function viewAllAssociationsUrl(location, elementType, elementId) {
  return keepContext(
    location,
    `/elements/${elementType}/${encodeURIComponent(elementId)}/associations`
  );
}

export function viewAllCollectionsUrl(location, elementType, elementId) {
  return keepContext(
    location,
    `/elements/${elementType}/${encodeURIComponent(elementId)}/collections/table/all`
  );
}

export function viewAllDnsHistoryUrl(location, elementType, elementId) {
  return keepContext(
    location,
    `/elements/${elementType}/${encodeURIComponent(elementId)}/dns_history/table/all`
  );
}

export function viewAllNotesUrl(location, elementType, elementId) {
  return keepContext(location, `/elements/${elementType}/${encodeURIComponent(elementId)}/notes`);
}

export function viewAssociationHistoryUrl(location, elementType, elementId) {
  return keepContext(location, `/elements/${elementType}/${encodeURIComponent(elementId)}/history`);
}

export const notificationsUrl = (location, page) => {
  return keepContext(location, `/notifications/${page}`);
};

export const notificationsListUrl = location => {
  return notificationsUrl(location, 'list');
};

export const notificationsLogUrl = location => {
  return notificationsUrl(location, 'logs');
};

export const viewAllThreatAssociationsUrl = location => {
  return keepContext(location, '/workspace/all/associations', ['workspace', 'collectionFilter']);
};

export const viewAllSummarySectionUrl = (location, section, tic) => {
  const url = `/workspace/all/${section}`;
  return keepContext(location, setParams(url, { tic }), ['workspace', 'collectionFilter']);
};

export const workspaceNotesUrl = location => {
  return keepContext(location, '/workspace/notes');
};

export const updateFilterType = (location, collectionFilter) => {
  const url = `${location.pathname}${location.search}`;
  return keepContext(location, setParams(url, { collectionFilter }));
};
