import React from 'react';
import { connect } from 'react-redux';
import { determineTokenAndAuth } from '../../utils/auth.js';

// IMPORTANT! sessionToken should not be used directly from Redux. By using determineTokenAndAuth(), when in oauth2
// auth mode sessionToken will be obtained directly via Okta component/package, avoiding a copy from possibly becoming stale.
const mapStateToProps = ({ auth }) => {
  return determineTokenAndAuth(auth);
};

export default WrappedComponent => {
  class WithSessionToken extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }
  return connect(mapStateToProps)(WithSessionToken);
};
