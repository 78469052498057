import React from 'react';
import injectStyles from 'react-jss';
import ClearIndicator from '../../../selects/ClearIndicator';
import Creatable from 'react-select/creatable';
import selectStyles from '../../../selects/styles';
import FieldError from '../../errors/FieldError';
import cx from 'classnames';
import styles from '../../styles';

export default injectStyles(styles)(
  ({
    classes,
    className,
    input: field,
    isSearchable = true,
    options,
    multi = false,
    meta,
    menuPortalTarget = document.body,
    disabled,
  }) => (
    <div className={cx(classes.field, className)}>
      <Creatable
        cacheOptions={false}
        className={classes.select}
        components={{ ClearIndicator }}
        defaultOptions={true}
        isDisabled={disabled}
        isMulti={multi}
        isSearchable={isSearchable}
        menuPortalTarget={menuPortalTarget}
        onChange={field.onChange}
        options={options}
        styles={selectStyles({ multi })}
        value={field.value}
      />
      <FieldError {...meta} />
    </div>
  )
);
