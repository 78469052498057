import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import injectStyles from 'react-jss';
import { injectIntl } from 'react-intl';

import Label from '../Label';
import { MdCheck as CheckIcon } from 'react-icons/md';

class RadioButton extends React.Component {
  static propTypes = {
    label: PropTypes.string,
    labelAfterInput: PropTypes.bool,
    labelId: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  };

  static defaultProps = {
    labelAfterInput: true,
  };

  render() {
    let {
      children,
      classes,
      className,
      checkedClassName,
      disabledClassName,
      disabled,
      id,
      intl,
      fieldClassName,
      label,
      labelAfterInput,
      labelClassName,
      labelId,
      onChange,
      selectedValue,
      showToolTip,
      tooltip,
      tooltipId,
      tooltipPosition,
      value,
    } = this.props;
    const checked = selectedValue === value;

    if (tooltipId) {
      tooltip = intl.formatMessage({
        defaultMessage: tooltip,
        id: tooltipId,
      });
    }

    return (
      <div
        className={cx(
          classes.radioContainer,
          className,
          checked && checkedClassName,
          disabled && disabledClassName
        )}>
        <Label
          id={id}
          className={cx(classes.label, labelClassName)}
          defaultMessage={label}
          fieldClassName={cx(classes.field, fieldClassName)}
          labelAfterInput={labelAfterInput}
          messageId={labelId}
          showToolTip={showToolTip}
          tooltip={tooltip}
          tooltipPosition={tooltipPosition}>
          <input
            checked={checked}
            disabled={disabled}
            onChange={() => onChange(value)}
            type="radio"
            value={value}
          />
          <span className={classes.fake}>
            <CheckIcon className={classes.check} size={10} />
          </span>
        </Label>
        {children}
      </div>
    );
  }
}

const styles = theme => ({
  check: {
    color: theme.colors.white,
    display: 'none',
  },
  field: {
    marginRight: 5,
    marginLeft: 15,
  },
  label: {
    /* input must be at least 1x1 (or visible) to have focus */
    '& input': {
      '&:checked ~ $fake': {
        '& $check': {
          display: 'block',
        },
        backgroundColor: theme.colors.actionColor,
        border: {
          color: theme.colors.actionColor,
        },
      },
      '&:focus ~ $fake': theme.focused,
      '&:hover ~ $fake': {
        /* this is here for completeness, currently there are no hover styles */
      },
      cursor: 'pointer',
      height: 1,
      position: 'absolute',
      opacity: 0,
      width: 1,
    },
    '-webkit-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    cursor: 'pointer',
    position: 'relative',
    userSelect: 'none',
    width: 'auto',
    marginRight: '6px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    marginBottom: '0px',
  },
  radioContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: 5,
    height: 28,
  },
  fake: {
    backgroundColor: theme.colors.white,
    border: {
      color: theme.colors.lightGray,
      style: 'solid',
      width: 1,
    },
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    height: '12px',
    width: '12px',
    marginTop: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default injectIntl(injectStyles(styles)(RadioButton));
