import 'whatwg-fetch';
import { apiRequest, apiRequestRaw, apiUrl } from '../lib/http';
import { API } from '../environment/api';
import { APIError } from '../utils/errors';

export function bootstrap(accessToken, idToken, idNonce) {
  return fetch(apiUrl(API.USERS_BOOTSTRAP), {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      accessToken,
      idNonce,
      idToken,
    }),
  });
}

export function getUser(sessionToken, userId) {
  return apiRequest(sessionToken, '/users/get', {
    'user-id': userId,
  }).then(({ result }) => {
    if (!result) {
      // for whatever reason this returns ok:true with a null result when the user
      // doesn't exist
      throw new APIError(404, 'User not found.');
    }
    return result;
  });
}

export function syncProfile(sessionToken, idNonce, idToken) {
  return apiRequestRaw('POST', API.USERS_SYNC_PROFILE, sessionToken, {
    body: {
      idNonce,
      idToken,
    },
  });
}
