import React from 'react';
import Select from 'react-select';
import ClearIndicator from './ClearIndicator';
import styles from './styles';

export default ({
  className,
  clearable = true,
  disabled,
  height,
  inverse = false,
  multi = false,
  meta,
  menuPortalTarget,
  onChange,
  options,
  overrideStyles,
  placeholder = 'Select...',
  searachable = true,
  value,
  ...rest
}) => (
  <Select
    className={className}
    classNamePrefix={className}
    components={{
      ClearIndicator,
    }}
    defaultOptions={true}
    isDisabled={disabled}
    isMulti={multi}
    isClearable={clearable}
    isSearchable={searachable}
    menuPortalTarget={menuPortalTarget}
    onChange={onChange}
    options={options}
    placeholder={placeholder}
    styles={styles({ inverse, multi, overrideStyles })}
    value={value}
    {...rest}
  />
);
