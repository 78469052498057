// API Quick Reference
// https://lgscout.atlassian.net/wiki/spaces/LGAPI/pages/111150926/ScoutPrime+API+Command+Summary

// By convention, each API path should begin with forward slash (/).

export const API = {
  // Security - https://lgscout.atlassian.net/wiki/spaces/LGAPI/pages/111150866/Security
  CHANGE_PASSWORD: '/security/change-password',

  // Collections - https://lgscout.atlassian.net/wiki/spaces/LGAPI/pages/112230582/Collections
  COLLECTIONS_CREATE: '/collections/create',
  COLLECTIONS_SYSTEM: '/collections/get-system-collections',
  COLLECTIONS_READ_ALL: '/collections/get-collections-by-workspace',
  COLLECTIONS_READ: '/collections/get',
  COLLECTIONS_UPDATE: '/collections/update',
  COLLECTIONS_DELETE: '/collections/delete',
  COLLECTIONS_OWNERS: '/collections/owners',
  COLLECTIONS_RULES: '/collections/rules',
  COLLECTION_MEMBERS: '/export/collection-members',
  COLLECTION_RULES_EXPORT: '/export/collection-rules',

  // DNS History
  DNS_HISTORY: '/elements/dns-history',

  // Element detail
  ELEMENT: '/elements/get',
  ELEMENT_PANELS_EXPORT: '/export/csv',

  // Feeds
  FEEDS_GET_ALL: '/feeds/all',
  FEED_GET: '/imports/by-feed',

  // CIP
  CIP_GET: '/cip/files',
  IMPORTS_UPDATE: '/imports/update',
  IMPORT_TX: '/import/tpx',
  IMPORT_STATS: '/imports/stats',

  // Graph
  GRAPH: '/graph',
  GRAPH_ANALYTICS: '/graph/analytics',
  GRAPH_QUERY: '/graph/query',

  // Incident Folders - https://lgscout.atlassian.net/wiki/spaces/LGAPI/pages/139986600/Incident+Folders+API
  INCIDENT_FOLDERS_CREATE: '/incidentFolders/create',
  INCIDENT_FOLDERS_READ_ALL: '/incidentFolders/query',
  INCIDENT_FOLDERS_READ: '/incidentFolders/get',
  INCIDENT_FOLDERS_UPDATE: '/incidentFolders/update',
  INCIDENT_FOLDERS_DELETE: '/incidentFolders/delete',

  // Incidents - https://lgscout.atlassian.net/wiki/spaces/LGAPI/pages/139986639/Incidents+API
  INCIDENTS_CREATE: '/incidents/create',
  INCIDENTS_READ_ALL: '/incidents/query',
  INCIDENTS_READ: '/incidents/get',
  INCIDENTS_UPDATE: '/incidents/update',
  INCIDENTS_DELETE: '/incidents/delete',

  // IPV4
  IPV4_SHODAN: '/ipv4/shodan',
  IPV_SHODAN: '/v1/elements/shodan',

  // Login - https://lgscout.atlassian.net/wiki/spaces/LGAPI/pages/127795386/Authentication
  LOGIN_URL: '/auth/login',
  LOGOUT_URL: '/auth/logout',
  VALIDATE_SESSION: '/auth/validate',

  // Notes - https://lgscout.atlassian.net/wiki/spaces/LGAPI/pages/137709506/Notes
  NOTES_CREATE: '/notes/create',
  NOTES_READ_ALL: '/notes/get-by-parent',
  NOTES_READ: '/notes/get',
  NOTES_UPDATE: '/notes/update',
  NOTES_DELETE: '/notes/delete',

  // Organizations - https://lgscout.atlassian.net/wiki/spaces/LGAPI/pages/111150861/Organizations
  ORGANIZATIONS_GET: '/organizations/get',
  ORGANIZATION_READ_ALL: '/organizations/tree',
  ORGANIZATION_UPDATE: '/organizations/update',

  // Permissions https://lgscout.atlassian.net/wiki/spaces/LGAPI/pages/111150866/Security#Security-/security/is-permitted
  PERMISSIONS_ASSIGNABLE: '/internal/users/me/permissions/assignable',
  PERMISSIONS_IS_PERMITTED: '/security/is-permitted',
  PERMISSIONS_GRANT: '/security/grant-permission',
  PERMISSIONS_REVOKE: '/security/revoke-permission',
  ACCESS_LEVEL_READ: '/security/get-access-level',
  ACCESS_LEVEL_SET: '/security/set-access-level',
  ACCESS_LEVEL_READ_ALL: '/security/get-access-levels',

  // Saved Searches - https://lgscout.atlassian.net/wiki/spaces/LGAPI/pages/139713241/Saved+Search+API
  SAVED_SEARCH_READ_ALL: '/saved-searches/query',
  SAVED_SEARCH_CREATE: '/saved-searches/create',
  SAVED_SEARCH_DELETE: '/saved-searches/delete',

  // Search
  // https://lgscout.atlassian.net/wiki/spaces/LGAPI/pages/137799726/Elements+API#ElementsAPI-elements/search
  SEARCH: '/elements/search',
  // https://lgscout.atlassian.net/wiki/spaces/LGAPI/pages/137799726/Elements+API#ElementsAPI-elements/associated-threats
  SEARCH_ASSOCIATED_THREATS: '/elements/associated-threats',
  // https://lgscout.atlassian.net/wiki/spaces/LGAPI/pages/137799726/Elements+API#ElementsAPI-elements/classifications
  THREAT_CLASSIFICATIONS: '/elements/classifications',
  SEARCH_WHOIS: '/whois/reverse',

  // Search History - https://lgscout.atlassian.net/wiki/spaces/LGAPI/pages/140514875/Search+History+API
  SEARCH_HISTORY_READ_ALL: '/search-history/query',
  SEARCH_HISTORY_CREATE: '/search-history/create',
  SEARCH_HISTORY_DELETE: '/search-history/delete',

  // System
  SYSTEM_AUTH: '/system/auth',
  SYSTEM_VERSION: '/system/version',

  // Users - https://lgscout.atlassian.net/wiki/spaces/LGAPI/pages/111150818/Users
  USERS_BOOTSTRAP: '/internal/users/bootstrap',
  USERS_CREATE: '/users/create',
  USERS_READ: '/users/get',
  USERS_READ_ALL: '/users/get-in-organization',
  USERS_UPDATE: '/users/update',
  USERS_DELETE: '/users/delete',
  USERS_ME: '/users/me',
  USERS_WITH_PERMISSION: '/users/users-with-permission',
  USERS_VALIDATE_TOKEN: '/users/validate-password-token',
  USERS_SET_PASSWORD: '/users/set-password',
  USERS_FORGOT_PASSWORD: '/users/forgot-password',
  USERS_RESET_PASSWORD: '/users/reset-password',
  USERS_SYNC_PROFILE: 'internal/users/sync/profile',

  // GROUPS -
  GROUPS_READ_ALL: '/groups/get-in-organization',
  GROUPS_UPDATE: '/groups/update',
  GROUPS_ADD_MEMBER: '/groups/add-member',
  GROUPS_REMOVE_MEMBER: '/groups/remove-member',
  GROUPS_DELETE: '/groups/delete',
  GROUPS_CREATE: '/groups/create',

  //WHOIS
  WHOIS: '/whois/forward',

  // Workspaces - https://lgscout.atlassian.net/wiki/spaces/LGAPI/pages/111150859/Workspaces
  WORKSPACES_CREATE: '/workspaces/create',
  WORKSPACES_READ: '/workspaces/get',
  WORKSPACES_READ_ALL: '/workspaces/get-in-organization',
  WORKSPACES_UPDATE: '/workspaces/update',
  WORKSPACES_DELETE: '/workspaces/delete',

  // Notifications - https://lgscout.atlassian.net/wiki/spaces/LGAPI/pages/828801355/Notification+Definitions
  NOTIFICATION_ALERTS: '/internal/notifications',
  NOTIFICATION_ALERTS_ACKNOWLEDGE: '/internal/notifications/acknowledge',
  NOTIFICATION_ALERTS_DELETE: '/internal/notifications',
  NOTIFICATION_ALERTS_UNREAD: '/internal/notifications/unacknowledged',
  NOTIFICATION_CREATE: '/notification-definitions/create',
  NOTIFICATION_DELETE: '/notification-definitions/delete',
  NOTIFICATIONS_GET: '/notification-definitions/get',
  NOTIFICATIONS_QUERY: '/notification-definitions/query',
  NOTIFICATION_UPDATE: '/notification-definitions/update',

  // Reports - https://lgscout.atlassian.net/wiki/spaces/LGAPI/pages/885457256/Reports+API
  REPORTS_CREATE: '/reports/create',
  REPORTS_DELETE: '/reports/delete',
  REPORTS_EMAIL: '/reports/email',
  REPORTS_STATUS: '/reports/status',
  REPORTS_QUERY: '/reports/query',

  // Scheduling Reports - https://lgscout.atlassian.net/wiki/spaces/LGAPI/pages/885457256/Reports+API
  SCHEDULES_CREATE: '/reports/schedules/create',
  SCHEDULES_DELETE: '/reports/schedules/delete',
  SCHEDULES_READ: '/reports/schedules/get',
  SCHEDULES_READ_WORKSPACE: '/reports/schedules/get-by-workspace',
  SCHEDULES_UPDATE: '/reports/schedules/update',
};
