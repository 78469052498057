import React from 'react';

// Components
import { Redirect, withRouter } from 'react-router-dom';
import { LoginCallback } from '@okta/okta-react';

// Redux
import { connect } from 'react-redux';
import { loginStart } from '../../../redux/modules/auth/actions';

import OktaError from './Error';

class Callback extends React.Component {
  state = {
    shouldBailOut: false,
  };

  onAuthResume = async () => {
    this.props.history.push('/login');
  };

  componentDidMount() {
    const { loginStart } = this.props;
    loginStart();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // if we're stuck on here (ex: the user refreshes on the callback page somehow) and
    // something handles that and stops the login process, respect that by getting unstuck
    if (prevProps.isLoggingIn && !this.props.isLoggingIn) {
      this.setState({ shouldBailOut: true });
    }
  }

  render() {
    if (this.state.shouldBailOut) {
      // this is handled via local state because this component is the component that sets
      // the isLoggingIn flag (in componentDidMount), thus if we immediately trusted that flag,
      // it would immediately redirect
      return <Redirect to="/login" />;
    }
    return (
      <LoginCallback {...this.props} errorComponent={OktaError} onAuthResume={this.onAuthResume} />
    );
  }
}

const mapStateToProps = ({ auth: { isLoggingIn } }) => ({
  isLoggingIn,
});

const mapDispatchToProps = {
  loginStart,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Callback)
);
