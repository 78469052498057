/***
 *
 */

import React from 'react';

// NPM Modules
import { default as SpinKit } from 'react-spinkit';
import injectStyles from 'react-jss';
import cx from 'classnames';

class Button extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <button
        type={this.props.type}
        onClick={this.props.onClick}
        className={cx(classes.button, this.props.className)}>
        {this.props.updating ? (
          <SpinKit fadeIn="none" color="#fff" name={'circle'} />
        ) : (
          this.props.children
        )}
      </button>
    );
  }
}

const styles = theme => ({
  button: {
    color: '#fff',
    padding: '0px 15px',
    width: 90,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    // boxShadow: '2px 2px 4px rgba(0,0,0,0.1)',
    textTransform: 'uppercase',
    fontSize: 14,
    backgroundColor: '#1e559a',
    borderColor: '#1e559a',
    border: '1px solid',
    height: 44,
    cursor: 'pointer',
  },
});

export default injectStyles(styles)(Button);
