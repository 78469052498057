import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer, REHYDRATE } from 'redux-persist';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import authActions from '../../redux/modules/auth/actions';
import promiseMiddleware from '../middleware/promiseMiddleware';
import reducers from '../modules';

const isProduction = process.env.NODE_ENV === 'production';

/**
 * Returns a redux store when running in development mode
 */
const developmentStore = (reducer, initialState) => {
  const middleware = [promiseMiddleware(), thunk];
  const enhancers = composeWithDevTools(applyMiddleware(...middleware));
  let store = createStore(reducer, initialState, enhancers);
  window.store = store;
  return store;
};

/**
 * Returns a redux store when running in production mode
 */
const productionStore = (reducer, initialState) => {
  const middleware = [promiseMiddleware(), thunk];
  return createStore(reducer, initialState, applyMiddleware(...middleware));
};

/**
 * Returns a map containing a redux store and persistor based on `initialState` and `options`
 */
const configureStore = initialState => {
  const persistanceConfiguration = {
    key: 'sp-web-ui',
    storage,
    stateReconciler: autoMergeLevel2,
    blacklist: ['entities', 'locale'],
  };

  /*
   * Unify module reducers for a single state tree
   * Add in purging of state on logout
   */
  const { app, auth, workspace, ...rest } = reducers;

  const appReducer = combineReducers({
    ...rest,
    app: persistReducer(
      {
        key: 'sp-web-ui/app',
        storage,
        whitelist: ['tic'],
      },
      app
    ),
    auth: persistReducer(
      {
        key: 'sp-web-ui/auth',
        storage,
        whitelist: ['apiTokens', 'sessionToken'],
        blacklist: ['logoutReason'],
      },
      auth
    ),
    workspace: persistReducer(
      {
        key: 'sp-web-ui/workspace',
        storage,
        whitelist: ['search'],
        // TODO: Add nested blacklist filtering for more optimized rehydration
        transforms: [],
      },
      workspace
    ),
  });

  const rootReducer = (state, action) => {
    if (action.type === `${authActions.LOGOUT}_SUCCESS`) {
      // Keep logoutReason
      state = {
        auth: {
          ...state.auth,
          logoutReason: state.auth.logoutReason,
        },
      };
    } else if (action.type === REHYDRATE) {
      let element = {};
      if (state.elementDetails) {
        element = state.elementDetails.element;
      }
      // Action interceptor to allow initial state to override incoming state
      state = {
        ...state,
        app: {
          ...state.app,
          statusBanner: {
            isShowing: false,
            message: '',
            type: 'info',
          },
        },
        app2: {
          ...state.app2,
          elementDetails: {
            ...state.elementDetails,
            element: {
              ...element,
              error: null,
            },
          },
        },
        auth: {
          ...state.auth,
          logoutReason: undefined,
        },
      };
    }
    return appReducer(state, action);
  };

  const persistedReducer = persistReducer(persistanceConfiguration, rootReducer);

  const store = isProduction
    ? productionStore(persistedReducer, initialState)
    : developmentStore(persistedReducer, initialState);
  return { store, persistor: persistStore(store) };
};

export { configureStore };
