/***
 * Component to handle all sorting
 */

import React from 'react';

// NPM Modules
import injectStyles from 'react-jss';
import cx from 'classnames';
import Dropdown, { DropdownTrigger, DropdownContent } from 'react-simple-dropdown';

class SortBy extends React.Component {
  constructor() {
    super();
    this.state = {
      isPopupActive: false,
    };
  }
  onShow = () => {
    this.setState({
      isPopupActive: !this.state.isPopupActive,
    });
  };
  onHide = () => {
    this.setState({
      isPopupActive: !this.state.isPopupActive,
    });
  };
  render() {
    const {
      classes,
      idPrefix,
      setSortDirection,
      setSortIndex,
      sortByDirection,
      sortByFieldIndex,
      sortByFields,
      sortByRef,
    } = this.props;
    const sortByLabel = sortByFields[sortByFieldIndex].label;
    const isAsc = sortByDirection === 'ASC';
    const isDesc = sortByDirection === 'DESC';
    return (
      <Dropdown
        className={classes.container}
        ref={sortByRef}
        onShow={this.onShow}
        onHide={this.onHide}>
        <DropdownTrigger className={classes.trigger} id={`${idPrefix}--sortby-trigger"`}>
          <button
            className={cx(classes.current, classes.buttonCleaned)}
            aria-expanded={this.state.isPopupActive}
            aria-haspopup="true"
            aria-label={`SortBy current value ${sortByLabel} ${
              isAsc ? 'Ascending' : 'Descending'
            }`}>
            Sort by <span>{sortByLabel}</span>
            {'  '}
            {isAsc ? (
              <i aria-hidden="true" className="fas fa-chevron-up" />
            ) : (
              <i aria-hidden="true" className="fas fa-chevron-down" />
            )}
          </button>
        </DropdownTrigger>
        <DropdownContent
          className={classes.content}
          role="dialog"
          tabIndex="0"
          aria-modal="true"
          aria-label="Sort By Collections"
          data-test="sortby-content-container">
          <div
            className={cx(classes.section, classes.field)}
            id="collection-management--sortby-field">
            <div
              role="heading"
              aria-label="Sort by field"
              aria-level="1"
              className={classes.section__header}
              tabIndex="0">
              Field
            </div>
            {sortByFields.map((filter, index) => {
              const isSelected = sortByFieldIndex === index;
              return (
                <div className={classes.filter} key={`filter_title_${index}_${filter.id}`}>
                  {isSelected && <i className={cx(classes.checkIcon, 'fas fa-check')} />}
                  <button
                    className={cx(
                      classes.section__value,
                      isSelected && classes.selected,
                      classes.buttonCleaned
                    )}
                    data-test="field"
                    aria-label={`Sort by ${filter.label}`}
                    role="checkbox"
                    aria-checked={isSelected}
                    onClick={() => setSortIndex(index)}>
                    {filter.label}
                  </button>
                </div>
              );
            })}
          </div>
          <div
            className={cx(classes.section, classes.direction)}
            id={`${idPrefix}--sortby-direction`}>
            <div
              className={classes.section__header}
              tabIndex="0"
              role="heading"
              aria-level="1"
              aria-label="Sort by direction">
              Direction
            </div>
            <div className={classes.filter}>
              {isAsc && <i aria-hidden="true" className={cx(classes.checkIcon, 'fas fa-check')} />}
              <button
                aria-label="Sort by ascending"
                role="checkbox"
                aria-checked={isAsc}
                className={cx(
                  classes.section__value,
                  isAsc && classes.selected,
                  classes.buttonCleaned
                )}
                data-test="direction"
                onClick={() => setSortDirection('ASC')}>
                ASC
              </button>
            </div>
            <div className={classes.filter}>
              {isDesc && <i aria-hidden="true" className={cx(classes.checkIcon, 'fas fa-check')} />}
              <button
                aria-label="Sort by descending"
                role="checkbox"
                aria-checked={isDesc}
                className={cx(
                  classes.section__value,
                  isDesc && classes.selected,
                  classes.buttonCleaned
                )}
                data-test="direction"
                onClick={() => setSortDirection('DESC')}>
                DESC
              </button>
            </div>
          </div>
        </DropdownContent>
      </Dropdown>
    );
  }
}

const styles = theme => ({
  checkIcon: {
    color: '#2568a2',
    position: 'absolute',
  },
  container: {},
  content: {
    background: '#fff',
    borderRadius: 5,
    boxShadow: 'rgba(0, 0, 0, 0.176) 0px 6px 12px',
    color: 'rgb(76, 80, 86)',
    fontSize: 11,
    fontWeight: 500,
    marginLeft: 60,
    marginTop: 12,
    padding: 10,
    width: 200,
    zIndex: 10,
  },
  current: {
    fontSize: 11,
    lineHeight: '15px',
    font: 'inherit',
    cursor: 'pointer',
  },
  buttonCleaned: {
    padding: 0,
    margin: 0,
    border: 'none',
    background: 'transparent',
  },
  direction: {},
  field: {},
  filter: {
    display: 'flex',
    alignItems: 'center',
  },
  section: {
    marginTop: 5,
  },
  section__header: {
    paddingLeft: 10,
    marginBottom: 3,
    textTransform: 'uppercase',
  },
  section__value: {
    fontSize: 12,
    height: 30,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 20,
    cursor: 'pointer',
  },
  selected: {
    color: '#2568a2',
    fontWeight: 700,
  },
  trigger: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
});

export default injectStyles(styles)(SortBy);
