/**
 * Returns an array of values if values is not an array
 * @param values
 * @returns {Array}
 */
export const normalizeToArray = values => {
  if (!values) {
    return [];
  }
  return Array.isArray(values) ? values : [values];
};
